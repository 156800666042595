import React from 'react'

import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { createIdentityToken } from '../store/thunks'
import { getPlatform, getVersion } from '../store/selectors'

import { EASY_BUTTON_IFRAME_URL, FRONTEND_WEB_APP_URL, SUPPORTED_COPILOT_PLUGINS } from '../constants'
import { isMessageEvent, isNonEmptyString } from '../type-guards'
import {
  type IncomingMessage,
  type MessageIdentifier,
  type OutgoingMessage,
  IncomingMessageType,
  OutgoingMessageType,
  isMessageIdentifier,
  isOutgoingMessage
} from '../universal-sidebar-communicator'

import Loading from '../components/loading'

import IframeCommunicator, { type Handler } from '../utils/iframe-communicator'

// ***** Types *****

type IframeStatus = 'loading' | 'loaded'

// ***** Constants *****

const IFRAME_NAME = 'easy-button-iframe'
const DEFAULT_IFRAME_STATUS: IframeStatus = 'loading'

/**
 * React Component to wrap children into a full-screen container.
 */
const FullScreenWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div
    className='w-full h-full flex justify-center items-center'
    children={children}
  />
)

/**
 * React Component that initializes the Beta-app sidebar-runner.
 */
const EasyButton: React.FC = () => {
  const ref = React.useRef<HTMLIFrameElement>(null)
  const [status, setStatus] = React.useState<IframeStatus>(DEFAULT_IFRAME_STATUS)

  const platform = useAppSelector(getPlatform)
  const version = useAppSelector(getVersion)
  const dispatch = useAppDispatch()

  /**
   * Post a message to the sidebar-runner.
   *
   * @param message The message to post.
   * @returns void
   */
  const postMessage = React.useCallback((message: MessageIdentifier<IncomingMessage>): void => {
    if (!ref.current?.contentWindow) return
    ref.current.contentWindow.postMessage(message, { targetOrigin: FRONTEND_WEB_APP_URL })
  }, [])

  /**
   * Handle a promise fulfillment. This function will send the value of the promise to the
   * sidebar-runner via the `IncomingMessageType.Promise` message type.
   *
   * @param message The message that was fulfilled.
   * @param value The value of the promise.
   * @returns void
   */
  const promiseFulfilled = React.useCallback((
    message: MessageIdentifier<unknown>,
    value?: unknown
  ): void => {
    postMessage({
      id: message.id,
      message: {
        type: IncomingMessageType.Promise,
        payload: {
          state: 'fulfilled',
          value
        }
      }
    })
  }, [postMessage])

  /**
   * Handle a promise rejection. This function will report the error to
   * Datadog and send the error to the sidebar-runner via the `IncomingMessageType.Promise`
   * message type.
   *
   * @param message The message that was rejected.
   * @param error The error that was thrown.
   * @returns void
   */
  const promiseRejected = React.useCallback((
    message: MessageIdentifier<unknown>,
    error: unknown
  ): void => {
    postMessage({
      id: message.id,
      message: {
        type: IncomingMessageType.Promise,
        payload: {
          state: 'rejected',
          value: error instanceof Error ? error.message : 'Unknown error.'
        }
      }
    })
  }, [postMessage])

  // Setup a message listener for messages coming from the beta-app...
  React.useEffect(() => {
    const initialize = async (message: MessageIdentifier<unknown>): Promise<void> => {
      try {
        // Create an identity token.
        const identityToken = await dispatch(createIdentityToken())

        // Failed to create an identity token...
        if (!isNonEmptyString(identityToken)) throw new Error('Failed to create an identity token.')

        // Send the identity token to the beta-app...
        promiseFulfilled(message, { identityToken })
      } catch (error) {
        // Failed to initialize the Easy-Button sidebar, notify Datadog...
        console.error(error)

        // ...and send the error to the beta-app for display. We do not want
        // to handle the error here, the beta-app should handle it.
        promiseRejected(message, error)
      }
    }

    const handler = (event: Event): void => {
      if (!(
        isMessageEvent(event) &&
        FRONTEND_WEB_APP_URL === event.origin &&
        isMessageIdentifier(event.data) &&
        isOutgoingMessage(event.data.message)
      )) return

      // The beta-app is requesting the Easy-Button sidebar to initialize...
      if (event.data.message.type === OutgoingMessageType.Initialize) {
        // ...so, the status can switch to loaded...
        setStatus('loaded')

        // ...and, we can initialize the Easy-Button sidebar.
        void initialize(event.data)
        return
      }

      // We want to handle that message here for now... At some point, this may move
      // into the platform...
      if (event.data.message.type === OutgoingMessageType.GetCopilotSupportedPluginIdentifiers) {
        promiseFulfilled(event.data, SUPPORTED_COPILOT_PLUGINS)
        return
      }

      // ...otherwise, transfer the message to the platform...
      IframeCommunicator.postMessage(event.data as MessageIdentifier<OutgoingMessage>)
    }

    window.addEventListener('message', handler)
    return (): void => {
      window.removeEventListener('message', handler)
    }
  }, [dispatch, promiseFulfilled, promiseRejected])

  // Setup a message listener for messages coming from the platform...
  React.useEffect(() => {
    const handler: Handler = (message): void => {
      if (!ref.current?.contentWindow || status !== 'loaded') return

      // Transmit the message to the beta-app...
      ref.current.contentWindow.postMessage(
        message,
        { targetOrigin: FRONTEND_WEB_APP_URL }
      )
    }

    return IframeCommunicator.addMessageListener(handler)
  }, [status])

  return (
    <FullScreenWrapper>
      {/* Iframe is still loading... Position the loading-indicator at the right height. */}
      {status === 'loading' && <Loading className='absolute top-0 bg-khaki-50' />}

      {/* Iframe is loaded, render... */}
      <iframe
        ref={ref}
        loading='lazy'
        title={IFRAME_NAME}
        name={IFRAME_NAME}
        id={IFRAME_NAME}
        src={`${EASY_BUTTON_IFRAME_URL}?platform=${platform}&version=${version}`}
        className='w-full h-full border-none p-0 m-0'
      />
    </FullScreenWrapper>
  )
}

export default EasyButton
