export const TEST_IDS = {
  // Header
  HEADER: 'header',
  HEADER_CONFIG_BUTTON: 'header-config-button',
  HEADER_MENU: 'header-menu',
  // Inactive account
  INACTIVE_ACCOUNT_MODAL: 'inactive-account-modal',
  // Consumer authentication error
  CONSUMER_AUTH_ERROR_MODAL: 'consumer-auth-error-modal',
  // Authentication
  AUTH_SIGN_IN_MODAL: 'auth-sign-in-modal',
  AUTH_SIGN_IN_BUTTON: 'auth-sign-in-button',
  AUTH_SIGN_IN_ERROR: 'auth-sign-in-error',
  AUTH_OAUTH_MODAL: 'auth-oauth-modal',
  AUTH_OAUTH_CODE: 'auth-oauth-code',
  AUTH_OAUTH_LINK: 'auth-oauth-link',
  // Onboarding
  ONBOARDING_WELCOME_MODAL: 'onboarding-welcome-modal',
  ONBOARDING_CHAT_MODAL: 'onboarding-chat-modal',
  ONBOARDING_START_TOUR_BUTTON: 'onboarding-start-tour-button',
  ONBOARDING_NEXT_BUTTON: 'onboarding-next-button',
  ONBOARDING_BACK_BUTTON: 'onboarding-back-button',
  // Error Modal
  ERROR_MODAL_BUTTON: 'error-modal-button'
}
