import React from 'react'
import { AnimatePresence, type HTMLMotionProps, type Variants, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

// ***** Constants *****

const MODAL_VARIANTS: Variants = {
  active: {
    opacity: 1,
    scale: 1
  },
  inactive: {
    opacity: 0,
    scale: 0.8
  }
}

// ***** Types *****

interface Props extends HTMLMotionProps<'div'> {
  readonly icon: React.ReactNode
  readonly primaryText: string
  readonly secondaryText?: React.ReactNode | string
}

// eslint-disable-next-line react/prop-types
const Modal: React.FC<Props> = ({
  icon,
  primaryText,
  secondaryText,
  children,
  className,
  ...props
}) => (
  <AnimatePresence>
    <motion.div
      variants={MODAL_VARIANTS}
      animate='active'
      initial='inactive'
      exit='inactive'
      transition={{ duration: 0.3 }}
      className={twMerge('relative min-w-[270px] max-w-[270px] min-h-[320px] bg-white rounded-[10px] shadow-lg p-[30px] flex flex-col justify-around', className)}
      {...props}
    >
      <div className='flex flex-col gap-8 items-center justify-center'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center justify-center'>{icon}</div>
          <h1 className='text-3xl font-semibold text-center font-bold'>{primaryText}</h1>
          {secondaryText && (
            <span className='text-xs font-normal text-center'>{secondaryText}</span>
          )}
        </div>
        <>{children}</>
      </div>
    </motion.div>
  </AnimatePresence>
)

export default Modal
