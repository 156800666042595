import {
  getLaunchDarklyFlags,
  getPlatform,
  getSubscriptionInfo,
  getUser,
  getVersion
} from '../store/selectors'

import { ENVIRONMENT } from '../constants'
import { isNonEmptyString } from '../type-guards'
import getError from './get-error'
import getFullName from './get-full-name'
import getFormattedLaunchDarklyFlags from './get-formatted-launch-darkly-flags'
import Datadog from './datadog'

import type { AppStore } from '../store/types'
import type { AnalyticsEventName, AnalyticsEventProperties } from '@gojasper/atlas-tracking-sdk'

// ***** Helper functions *****

const isEnabled = (): boolean => (
  // Ignore Analytics setup in `development` mode.
  ENVIRONMENT !== 'development'
)

class Analytics {
  private static instance: Analytics

  private readonly store: AppStore

  constructor (store: AppStore) {
    this.store = store
  }

  public static init (store: AppStore): Analytics {
    if (Analytics.instance) return Analytics.instance

    Analytics.instance = new Analytics(store)
    return Analytics.instance
  }

  public static getInstance (): Analytics {
    if (!Analytics.instance) throw new Error('init() must be called first.')
    return Analytics.instance
  }

  /**
   * Identify the current user in the Atlas context.
   */
  public static identify (): void {
    try {
      if (!isEnabled()) return

      const instance = this.getInstance()
      const state = instance.store.getState()
      const platform = getPlatform(state)
      const version = getVersion(state)
      const user = getUser(state)
      const { productLabel } = getSubscriptionInfo(state)
      const flags = getFormattedLaunchDarklyFlags(getLaunchDarklyFlags(state))

      // User should be loaded, but make sure it is.
      if (!isNonEmptyString(user.id)) return

      // Identify the current user with Atlas.
      void window.atlas.identify({
        userId: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        name: getFullName(user),
        product: productLabel,
        promoId: user.workspace.promoId,
        workspaceId: user.workspace.id,
        workspaceName: user.workspace.name,
        workspaceUserCount: user.workspaceCount,
        deviceId: user.workspace.customerId,
        platform,
        version,
        ...flags
      })
    } catch (e) {
      Datadog.error({
        message: getError(e),
        error: e instanceof Error ? e : undefined
      })
    }
  }

  /**
   * Track an event in Atlas
   *
   * @param event Event name.
   * @param payload Required payload for the given `event` name.
   */
  public static track <T extends AnalyticsEventName>(
    event: T,
    payload: AnalyticsEventProperties<T>
  ): void {
    try {
      if (!isEnabled()) return

      const instance = this.getInstance()
      const state = instance.store.getState()
      const platform = getPlatform(state)
      const version = getVersion(state)

      // Propagate event to Atlas...
      void window.atlas.track(event, {
        platform,
        version,
        useV2: true,
        ...(payload ?? {})
      })
    } catch (e) {
      Datadog.error({
        message: getError(e),
        error: e instanceof Error ? e : undefined
      })
    }
  }
}

export default Analytics
