import { datadogLogs } from '@datadog/browser-logs'

import {
  APP_NAME,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SITE,
  ENVIRONMENT,
  VERSION
} from '../constants'
import { isNonEmptyString } from '../type-guards'
import { getPlatform, getUser, getVersion } from '../store/selectors'
import getFullName from './get-full-name'

import type { AppStore } from '../store/types'

// ***** Types *****

interface LogOption {
  readonly message: string
  readonly context?: object
  readonly error?: Error
}

// ***** Helper functions *****

const isEnabled = (): boolean => (
  // Ignore Datadog setup in `development` mode...
  ENVIRONMENT !== 'development' &&
  // ...and, a client-token hasn't been provided...
  isNonEmptyString(DATADOG_CLIENT_TOKEN) &&
  // ...and, a datadog-site hasn't been provided.
  isNonEmptyString(DATADOG_SITE)
)

class Datadog {
  private static instance: Datadog

  private readonly store: AppStore

  constructor (store: AppStore) {
    this.store = store

    if (!isEnabled()) return

    // Initialize Datadog...
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      env: ENVIRONMENT,
      version: VERSION,
      service: APP_NAME,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100
    })

    const state = this.store.getState()
    const platform = getPlatform(state)
    const version = getVersion(state)

    // Setup global context properties...
    datadogLogs.setGlobalContextProperty('CONSUMER_PLATFORM', platform)
    datadogLogs.setGlobalContextProperty('CONSUMER_VERSION', version)
  }

  public static init (store: AppStore): Datadog {
    if (Datadog.instance) return Datadog.instance

    Datadog.instance = new Datadog(store)
    return Datadog.instance
  }

  public static getInstance (): Datadog {
    if (!Datadog.instance) throw new Error('init() must be called first.')
    return Datadog.instance
  }

  /**
   * Identify the current user with Datadog for better error logging.
   */
  public static identify (): void {
    if (!isEnabled()) return

    const instance = this.getInstance()
    const state = instance.store.getState()
    const user = getUser(state)

    datadogLogs.setUser({
      id: user.id,
      email: user.email,
      name: getFullName(user),
      workspace: {
        id: user.workspace.id,
        name: user.workspace.name,
        active: user.workspace.active,
        locked: user.workspace.locked
      }
    })
  }

  /**
   * Flush the Datadog user from the context.
   */
  public static clear (): void {
    if (!isEnabled()) return

    datadogLogs.clearUser()
  }

  /**
   * Log error to Datadog, automatically infer user and global context
   * to the log.
   *
   * @param option Object of option to use for logging error.
   */
  public static error (option: LogOption): void {
    if (!isEnabled()) return

    datadogLogs.logger.log(option.message, option.context, 'error', option.error)
  }
}

export default Datadog
