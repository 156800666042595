import { v4 } from 'uuid'

import { getPlatform } from '../store/selectors'
import { validateIdentityToken } from '../store/thunks'
import { actions as configActions } from '../store/slices/config'
import { actions as authenticationActions } from '../store/slices/authentication'

import { isNonEmptyString, isString } from '../type-guards'
import { ENVIRONMENT, PLATFORM_ORIGIN_BY_ENVIRONMENT } from '../constants'
import {
  type MessageIdentifier,
  type OutgoingMessage,
  IncomingMessageType,
  isIncomingMessage,
  isMessageIdentifier
} from '../universal-sidebar-communicator'
import Firebase from './firebase'

import type { AppStore } from '../store/types'
import Datadog from './datadog'

// ***** Types *****

export type Handler = (message: MessageIdentifier<unknown>) => void
type Disposer = () => void

/**
 * @class IframeCommunicator
 *
 * Class responsible for initializing a `message` listener on the current window.
 * It filters the incoming messages, verifies their origin and process the message
 * if necessary.
 *
 * Future improvement:
 *      - outgoing communication with consumer (`universal-sidebar-communicator`)
 */
class IframeCommunicator {
  private static instance: IframeCommunicator

  private readonly store: AppStore

  public readonly listeners = new Set<Handler>()

  private parent: MessageEventSource | null

  constructor (store: AppStore) {
    this.store = store
    this.parent = null
    this.setupListeners()
  }

  public static init (store: AppStore): IframeCommunicator {
    if (IframeCommunicator.instance) return IframeCommunicator.instance

    IframeCommunicator.instance = new IframeCommunicator(store)
    return IframeCommunicator.instance
  }

  public static getInstance (): IframeCommunicator {
    if (!IframeCommunicator.instance) throw new Error('init() must be called first.')
    return IframeCommunicator.instance
  }

  private setupListeners (): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    window.addEventListener('message', this.messageHandler.bind(this))
  }

  /**
   * Adds a listener to the `message` event. This is useful to listen to
   * messages coming from the platform that need to be re-transmitted to
   * the beta-app.
   *
   * @param handler A function that will be called when a message is received.
   * @returns A function that can be called to remove the listener.
   */
  public static addMessageListener (handler: Handler): Disposer {
    const instance = this.getInstance()

    instance.listeners.add(handler)
    return (): void => {
      instance.listeners.delete(handler)
    }
  }

  private async messageHandler (event: MessageEvent): Promise<void> {
    const state = this.store.getState()
    const platform = getPlatform(state)
    const expectedOrigin = PLATFORM_ORIGIN_BY_ENVIRONMENT[platform][ENVIRONMENT]

    // Security check - this is important to verify the origin of the message.
    if (
      (isString(expectedOrigin) && expectedOrigin !== event.origin) ||
      (!isString(expectedOrigin) && !expectedOrigin.test(event.origin))
    ) return

    // Verify the event message is correct.
    if (!isMessageIdentifier(event.data) || !isIncomingMessage(event.data.message)) return

    // Message reducer.
    switch (event.data.message.type) {
      case IncomingMessageType.Initialize: {
        // Save a reference of the source that initialized the sidebar...
        this.parent = event.source

        // Save the sidebar configuration in the store...
        this.store.dispatch(configActions.setConfiguration(
          event.data.message.payload.configuration
        ))

        // Universal-sidebar should handles authentication...
        if (event.data.message.payload.configuration.authentication === 'built-in') {
          await Firebase.getInstance().restore()
        }

        // otherwise, initialize the login process if an identity-token has been provided...
        else if (isNonEmptyString(event.data.message.payload.identityToken)) {
          await this.store.dispatch(validateIdentityToken(
            event.data.message.payload.identityToken
          ))
        }

        // otherwise, this is an issue, reports to Datadog for further investigation.
        else {
          Datadog.error({
            message: 'Authentication cannot be set to `consumer` without passing an `identityToken`.',
            context: event.data.message
          })
        }

        // Authentication initialization is done...
        this.store.dispatch(authenticationActions.setInitialized(true))

        break
      }

      case IncomingMessageType.Promise: {
        this.listeners.forEach((listener) => {
          listener(event.data)
        })
        break
      }

      default: {
        break
      }
    }
  }

  public static postMessage (message: OutgoingMessage | MessageIdentifier<OutgoingMessage>): void {
    const instance = this.getInstance()
    const state = instance.store.getState()
    const platform = getPlatform(state)
    const origin = PLATFORM_ORIGIN_BY_ENVIRONMENT[platform][ENVIRONMENT]

    // Makes sure the Sidebar has been initialized first.
    if (!instance.parent) throw new Error('IncomingMessageType.Initialize must be send first.')

    // Constructs our message.
    let identifiedMessage: MessageIdentifier<OutgoingMessage> | null = null
    if (isMessageIdentifier(message)) identifiedMessage = message
    else identifiedMessage = { id: v4(), message }

    // Sends the message to the consumer.
    instance.parent.postMessage(identifiedMessage, {
      // Ignore Regex origins... (unsecure).
      targetOrigin: isString(origin) ? origin : '*'
    })
  }
}

export default IframeCommunicator
