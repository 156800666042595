import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from '../store/types'

// ***** Types *****

type DispatchFunc = () => AppDispatch

/**
 * Strictly typed hook to dispatch Redux actions. Should be use instead
 * of the classic `useDispatch` hook.
 */
export const useAppDispatch: DispatchFunc = useDispatch

/**
 * Strictly typed hook to get Redux selectors. Should be use instead
 * of the classic `useSelector` hook.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
