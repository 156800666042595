import { Loader } from '@gojasper/base-ui/ava'
import React from 'react'
import { twMerge } from 'tailwind-merge'

// ***** Types *****

type Props = React.HTMLAttributes<HTMLDivElement>

// eslint-disable-next-line react/prop-types
const Loading: React.FC<Props> = ({ className, ...props }) => (
  <div className={twMerge('w-screen h-screen flex justify-center items-center', className)} {...props}>
    <Loader />
  </div>
)

export default Loading
