import { a as r, A as t } from "./consts-3d3b7db3.mjs";
import { I as m } from "./types-ac618773.mjs";
import { jsx as o } from "react/jsx-runtime";
import { C as p } from "./color-a1f65061.mjs";
const S = ({
  Component: i,
  style: n = m.Line,
  size: a = t.M,
  color: s = p.Black
}) => {
  const e = r[a];
  return /* @__PURE__ */ o("span", { className: e.className, children: /* @__PURE__ */ o(i, { ...e, size: a, stroke: s, fill: s, color: s, style: n }) });
};
export {
  S as Icon,
  t as IconSizes,
  m as IconStyles
};
