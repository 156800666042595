import { INACTIVE_USER_ERROR, OAUTH_FETCH_TOKEN_MAX_ATTEMPTS, OAUTH_FETCH_TOKEN_POLL_MS } from '../constants'
import {
  isCustomToken,
  isGraphQLEntitlements,
  isGraphQLOAuthCode,
  isGraphQLOAuthToken,
  isInactiveUser,
  isNonEmptyString,
  isUser
} from '../type-guards'

import Datadog from '../utils/datadog'
import Analytics from '../utils/analytics'
import LaunchDarkly from '../utils/launch-darkly'
import Firebase from '../utils/firebase'
import getError from '../utils/get-error'
import request from '../utils/request'
import { requestGraphQL } from '../utils/request-graphql'
import { delay } from '../utils/promisify-delay'

import { clearStore } from './actions'
import { getAuthenticationIdToken, getFetchOAuthCode, getOAuthCode, getOAuthExpiry, getOAuthLink } from './selectors'
import { actions as requestActions } from './slices/request'

import type { AppThunkAction } from './types'

/**
 * Triggers all required requests to initialize the application.
 */
export const initialize = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    await Promise.all([
      dispatch(fetchUser()),
      dispatch(fetchEntitlements())
    ])
  }

/**
 * Clear the entire global store, as well as clean all third party
 * packages using the user object actively.
 */
export const clear = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    // Clear the Redux store...
    dispatch(clearStore())

    // Clear the Datadog user from the Datadog context...
    Datadog.clear()

    // Clear the Launch-Darkly user from the Launch-Darkly context...
    await LaunchDarkly.clear()
  }

/**
 * Fetches the current user object.
 */
export const fetchUser = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(requestActions.fetchPending({ request: 'fetch-user', time: Date.now() }))

      const data = await dispatch(request('_workspaces/getUser'))
      if (isInactiveUser(data)) throw new Error(INACTIVE_USER_ERROR)
      if (!isUser(data)) throw new Error('Data isn\'t of type `User`.')

      dispatch(requestActions.fetchSuccess({ request: 'fetch-user', data }))

      // Identify the current user with Datadog...
      Datadog.identify()

      // Identify the current user with Launch-Darkly...
      // Important: this must be awaited, otherwise the LD-flags will be
      // stale while identifying the user in `Analytics`.
      await LaunchDarkly.identify()

      // Identify the current user with our Analytics system...
      Analytics.identify()
    } catch (e) {
      const message = getError(e)
      dispatch(requestActions.fetchFailed({ request: 'fetch-user', message: getError(e) }))

      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message,
        error: e instanceof Error ? e : undefined
      })
    }
  }

/**
 * Fetches the current user's entitlements'.
 */
export const fetchEntitlements = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(requestActions.fetchPending({ request: 'fetch-entitlements', time: Date.now() }))

      // Currently we get entitlements through Functions, which in turn gets them
      // from Weaver via gRPC. Eventually we want to get them directly from Weaver
      // through GraphQL.
      const data = await dispatch(requestGraphQL('getEntitlements'))
      if (!isGraphQLEntitlements(data)) throw new Error('Data isn\'t of type `Entitlement[]`.')

      dispatch(requestActions.fetchSuccess({ request: 'fetch-entitlements', data: data.viewer.entitlements }))
    } catch (e) {
      const message = getError(e)
      dispatch(requestActions.fetchFailed({ request: 'fetch-entitlements', message }))

      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message,
        error: e instanceof Error ? e : undefined
      })
    }
  }

/**
 * Signs in the user using OAuth.
 */
export const signIn = (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    // Retrieve an OAuth code to begin the sign-in process...
    await dispatch(fetchOAuthCode())

    const state = getState()

    // Make sure the request was successful...
    const fetchOAuthCodeState = getFetchOAuthCode(state)
    if (fetchOAuthCodeState.failed) return

    // Open the OAuth page in a new tab...
    window.open(getOAuthLink(state), '_blank')

    // Starts polling for the OAuth token...
    await dispatch(pollOAuthToken())
  }

/**
 * Polls for the OAuth token.
 */
const pollOAuthToken = (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    let state = getState()
    let retryNo = 1
    let oauthCode = getOAuthCode(state)
    let oauthExpiry = getOAuthExpiry(state)
    let authToken = getAuthenticationIdToken(state)

    while (
      !authToken &&
      oauthCode &&
      oauthExpiry > Date.now() &&
      retryNo < OAUTH_FETCH_TOKEN_MAX_ATTEMPTS
    ) {
      await dispatch(fetchOAuthToken())
      await delay(OAUTH_FETCH_TOKEN_POLL_MS)

      state = getState()
      oauthCode = getOAuthCode(state)
      oauthExpiry = getOAuthExpiry(state)
      authToken = getAuthenticationIdToken(state)
      retryNo++
    }
  }

/**
 * Fetches an OAuth code.
 */
const fetchOAuthCode = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(requestActions.fetchPending({ request: 'fetch-oauth-code', time: Date.now() }))

      const data = await dispatch(requestGraphQL('getOAuthCode'))
      if (!isGraphQLOAuthCode(data)) throw new Error('Data isn\'t of type `FetchGraphQLOAuthCode`.')

      dispatch(requestActions.fetchSuccess({ request: 'fetch-oauth-code', data }))
    } catch (e) {
      const message = getError(e)
      dispatch(requestActions.fetchFailed({ request: 'fetch-oauth-code', message }))

      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message,
        error: e instanceof Error ? e : undefined
      })
    }
  }

/**
 * Fetches an OAuth token.
 */
const fetchOAuthToken = (): AppThunkAction<Promise<void>> =>
  async (dispatch, getState): Promise<void> => {
    try {
      const state = getState()
      const code = getOAuthCode(state)

      dispatch(requestActions.fetchPending({ request: 'fetch-oauth-token', time: Date.now() }))

      const data = await dispatch(requestGraphQL('getOAuthToken', { code }))
      if (!isGraphQLOAuthToken(data)) throw new Error('Data isn\'t of type `FetchGraphQLOAuthToken`.')

      // We do not want to authenticate the user if the token is empty... But this
      // isn't an error either...
      if (!isNonEmptyString(data.oauthToken.token)) return

      // Setup Firebase with the token...
      await Firebase.getInstance().authenticate(data.oauthToken.token)

      dispatch(requestActions.fetchSuccess({ request: 'fetch-oauth-token', data }))
    } catch (e) {
      const message = getError(e)
      dispatch(requestActions.fetchFailed({ request: 'fetch-oauth-token', message }))

      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message,
        error: e instanceof Error ? e : undefined
      })
    }
  }

/**
 * Validate a login code and email.
 */
export const validateIdentityToken = (token: string): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    try {
      if (!isNonEmptyString(token)) throw new Error('Token must be provided.')

      dispatch(requestActions.fetchPending({ request: 'validate-identity-token', time: Date.now() }))

      const data = await dispatch(request('_workspaces/validateIdentityToken', {
        method: 'POST',
        body: JSON.stringify({ token }),
        public: true
      }))
      if (!isCustomToken(data)) throw new Error('Data isn\'t of type `IdentityToken`.')

      // Setup Firebase with the token...
      await Firebase.getInstance().authenticate(data.token)

      dispatch(requestActions.fetchSuccess({ request: 'validate-identity-token' }))
    } catch (e) {
      const message = getError(e)
      dispatch(requestActions.fetchFailed({ request: 'validate-identity-token', message }))

      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message,
        error: e instanceof Error ? e : undefined
      })
    }
  }

/**
 * Create an identity token for the current user.
 */
export const createIdentityToken = (): AppThunkAction<Promise<string>> =>
  async (dispatch): Promise<string> => {
    try {
      const data = await dispatch(request('_workspaces/createIdentityToken', {
        method: 'POST'
      }))
      if (!isCustomToken(data)) throw new Error('Data isn\'t of type `IdentityToken`.')

      return data.token
    } catch (e) {
      // Log / report error to Datadog for further investigation...
      Datadog.error({
        message: 'Failing creating identity token.',
        error: e instanceof Error ? e : undefined
      })

      return ''
    }
  }

/**
 * Signs out the current user
 */
export const signOut = (): AppThunkAction<Promise<void>> =>
  async (dispatch): Promise<void> => {
    // Log-out the current user from the Firebase instance.
    await Firebase.getInstance().logout()

    // Clear the Redux store, as well as all third-parties SDK.
    await dispatch(clear())
  }
