import { jsx as e, jsxs as l } from "react/jsx-runtime";
import { A as s } from "../consts-3d3b7db3.mjs";
import { I as o } from "../types-ac618773.mjs";
import { C as r } from "../color-a1f65061.mjs";
const a = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: /* @__PURE__ */ e("g", { children: /* @__PURE__ */ e(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.25",
    d: "m2.876 2.876 8.249 8.248M12.833 7A5.833 5.833 0 1 1 1.167 7a5.833 5.833 0 0 1 11.667 0Z"
  }
) }) }), d = a, w = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: /* @__PURE__ */ l("g", { children: [
  /* @__PURE__ */ e("path", { fill: t, d: "M7 12.833A5.833 5.833 0 1 0 7 1.167a5.833 5.833 0 0 0 0 11.666", opacity: ".12" }),
  /* @__PURE__ */ e(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.25",
      d: "m2.876 2.876 8.249 8.248M12.833 7A5.833 5.833 0 1 1 1.167 7a5.833 5.833 0 0 1 11.667 0Z"
    }
  )
] }) }), g = w, p = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: /* @__PURE__ */ e("g", { children: /* @__PURE__ */ e(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.5",
    d: "m3.286 3.287 9.427 9.426M14.666 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.333 0Z"
  }
) }) }), u = p, C = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: /* @__PURE__ */ l("g", { children: [
  /* @__PURE__ */ e("path", { fill: t, d: "M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334", opacity: ".12" }),
  /* @__PURE__ */ e(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.5",
      d: "m3.286 3.287 9.427 9.426M14.666 8A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.333 0Z"
    }
  )
] }) }), m = C, k = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: /* @__PURE__ */ e("g", { children: /* @__PURE__ */ e(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "m4.109 4.108 11.783 11.784M18.334 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0Z"
  }
) }) }), L = k, f = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: /* @__PURE__ */ l("g", { children: [
  /* @__PURE__ */ e("path", { fill: t, d: "M10 18.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666", opacity: ".12" }),
  /* @__PURE__ */ e(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "m4.109 4.108 11.783 11.784M18.334 10a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0Z"
    }
  )
] }) }), v = f, S = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ e("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: /* @__PURE__ */ e(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "m4.93 4.93 14.14 14.14M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
  }
) }), i = S, M = ({ color: t = r.CurrentColor }) => /* @__PURE__ */ l("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: [
  /* @__PURE__ */ e("path", { fill: t, d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10", opacity: ".12" }),
  /* @__PURE__ */ e(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "m4.93 4.93 14.14 14.14M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Z"
    }
  )
] }), n = M, x = {
  [s.XS]: {
    [o.Line]: (t) => /* @__PURE__ */ e(d, { color: t }),
    [o.Duo]: (t) => /* @__PURE__ */ e(g, { color: t }),
    [o.Filled]: (t) => null
  },
  [s.S]: {
    [o.Line]: (t) => /* @__PURE__ */ e(u, { color: t }),
    [o.Duo]: (t) => /* @__PURE__ */ e(m, { color: t }),
    [o.Filled]: (t) => null
  },
  [s.M]: {
    [o.Line]: (t) => /* @__PURE__ */ e(L, { color: t }),
    [o.Duo]: (t) => /* @__PURE__ */ e(v, { color: t }),
    [o.Filled]: (t) => null
  },
  [s.L]: {
    [o.Line]: (t) => /* @__PURE__ */ e(i, { color: t }),
    [o.Duo]: (t) => /* @__PURE__ */ e(n, { color: t }),
    [o.Filled]: (t) => null
  },
  [s.XL]: {
    [o.Line]: (t) => /* @__PURE__ */ e(i, { color: t }),
    [o.Duo]: (t) => /* @__PURE__ */ e(n, { color: t }),
    [o.Filled]: (t) => null
  }
}, Z = ({ size: t, style: h, color: c }) => x[t][h](c);
export {
  Z as SlashCircle,
  Z as default
};
