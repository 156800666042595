import { CacheLocation, FpjsClient } from '@fingerprintjs/fingerprintjs-pro-spa'
import { FINGERPRINT_JS_ENDPOINT, FINGERPRINT_JS_SCRIPT_URL_PATTERN, FINGERPRINT_JS_TOKEN } from '../constants'

// Device cache keys used for client side storage
// (Must be named in a way that will avoid collisions with other libraries.)
const DEVICE_CACHE_KEY = 'jasper_fid'
const DEVICE_HASH_CACHE_KEY = 'jasper_fph'

const FPJS_REQUEST_ACTIVE = 'jasper_fpra'

// Old cache keys, which may be colliding with 3rd-party code -- see AM-165
// TODO: We don't want to switch everyone over to the new cache keys all
//   at once because it would likely trigger throttling on our Fingerprint
//   account. After a sufficient number of users have been switched to the
//   new cache key we can remove the code that falls back to these older
//   keys.
const OLD_DEVICE_CACHE_KEY = 'fpid'
const OLD_DEVICE_HASH_CACHE_KEY = '_fph'

const OBJECT_AS_STRING = '[object Object]'

enum RequestStatuses {
  Active = 'Active',
  Complete = 'Complete',
}

const MAX_FPJS_RETRIES = 10
const FPJS_WAIT = 100

interface FingerprintDevice {
  deviceId: string
  deviceHash: string
}

interface SecurityFingerprintDevice {
  dt: string
  dth: string
}

export const getHash = (text: string | null = ''): string | null => {
  if (!text || text.length === 0) return null
  let hash = 0

  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return String(hash)
}

/**
 * Get the cached device
 *
 * If no value is found for deviceId or deviceHash, we fall back to the
 * old key values (which are more susceptible to collisions).
 *
 * If an attempt was made to store an object as the cached value, a
 * warning will be logged and an object with null values will be returned.
 *
 * TODO: Remove references to old hash keys once a sufficient number of
 *   users have been switched to the new keys that we aren't worried about
 *   hitting a throttling limit on our Fingerprint account.
 */
const getCachedDevice = (): FingerprintDevice => {
  let cacheKeys = { deviceId: DEVICE_CACHE_KEY, deviceHash: DEVICE_HASH_CACHE_KEY }
  let device: FingerprintDevice = {
    deviceId: window.localStorage.getItem(cacheKeys.deviceId) ?? '',
    deviceHash: window.localStorage.getItem(cacheKeys.deviceHash) ?? ''
  }

  // until we've fully migrated to the new device cache keys, fall back to the old keys
  // (see comments at top)
  if (!device.deviceId || !device.deviceHash) {
    cacheKeys = { deviceId: OLD_DEVICE_CACHE_KEY, deviceHash: OLD_DEVICE_HASH_CACHE_KEY }
    device = {
      deviceId: window.localStorage.getItem(cacheKeys.deviceId) ?? '',
      deviceHash: window.localStorage.getItem(cacheKeys.deviceHash) ?? ''
    }
  }

  // If `localStorage.setItem(value)` is called with an object, the object will actually
  // be saved in [Chrome] local storage as the string value '[object Object]'.
  if (device.deviceId === OBJECT_AS_STRING || device.deviceHash === OBJECT_AS_STRING) {
    console.warn(`Attempt was made to store an invalid (object) value for device keys ${JSON.stringify(cacheKeys)}`)
    device = { deviceId: '', deviceHash: '' }
  }

  return device
}

const setCachedDevice = (deviceId: string): void => {
  window.localStorage.setItem(DEVICE_CACHE_KEY, deviceId)
  window.localStorage.setItem(DEVICE_HASH_CACHE_KEY, getHash(deviceId) ?? '')
}

const removeCachedDevice = (): void => {
  window.localStorage.removeItem(DEVICE_CACHE_KEY)
  window.localStorage.removeItem(DEVICE_HASH_CACHE_KEY)
}

const isValidDevice = (deviceId: string, deviceHash: string): boolean => {
  if (!deviceId || !deviceHash) {
    return false
  }

  return getHash(deviceId) === deviceHash
}

const requestInProgress = (): boolean => (
  window.sessionStorage.getItem(FPJS_REQUEST_ACTIVE) === RequestStatuses.Active
)

const setRequestInProgress = (status: RequestStatuses): void => {
  window.sessionStorage.setItem(FPJS_REQUEST_ACTIVE, status)
}

const removeRequestInProgress = (): void => {
  window.sessionStorage.removeItem(FPJS_REQUEST_ACTIVE)
}

export const getDeviceId = async (iteration: number = 0): Promise<SecurityFingerprintDevice> => {
  // If the request is in progress during the first iteration, then the progress status
  // needs to be cleared.
  // This scenario would occur if fingerprint identification is in progress, but the user
  // leaves the page.
  // The status would remain as "In Progress" when the user returns to the page, causing the user to
  // be stuck in an infinite loop of "In Progress".
  if (requestInProgress() && iteration === 0) {
    removeRequestInProgress()
  }

  if (requestInProgress() || iteration < MAX_FPJS_RETRIES) {
    const newIteration = iteration + 1

    const { deviceId, deviceHash } = getCachedDevice()
    if (isValidDevice(deviceId, deviceHash)) {
      return { dt: deviceId, dth: deviceHash }
    }

    await new Promise((resolve) => setTimeout(resolve, FPJS_WAIT))
    return await getDeviceId(newIteration)
  }

  setRequestInProgress(RequestStatuses.Active)
  const deviceBody = await getDeviceRequest()
  setRequestInProgress(RequestStatuses.Complete)
  return deviceBody
}

export const getDeviceRequest = async (): Promise<SecurityFingerprintDevice> => {
  const { deviceId, deviceHash } = getCachedDevice()

  try {
    if (!isValidDevice(deviceId, deviceHash)) {
      removeCachedDevice()
      const client = await new FpjsClient({
        cacheTimeInSeconds: 7000,
        cacheLocation: CacheLocation.LocalStorage,
        loadOptions: {
          apiKey: FINGERPRINT_JS_TOKEN,
          endpoint: FINGERPRINT_JS_ENDPOINT,
          ...(!!FINGERPRINT_JS_SCRIPT_URL_PATTERN && {
            scriptUrlPattern: FINGERPRINT_JS_SCRIPT_URL_PATTERN
          })
        }
      }).init()
      const data = await client.get()
      const deviceId = data.visitorId
      setCachedDevice(deviceId)
      const info = getCachedDevice()
      return { dt: info.deviceId, dth: info.deviceHash }
    }
  } catch (err) {
    console.error(err)
  }

  return { dt: deviceId, dth: deviceHash }
}
