const o = 0.12;
var t = /* @__PURE__ */ ((e) => (e.XS = "XS", e.S = "S", e.M = "M", e.L = "L", e.XL = "XL", e))(t || {});
const i = Object.freeze({
  width: 14,
  height: 14,
  className: "min-w-[14px] min-h-[14px]",
  viewbox: "0 0 24 24",
  strokeWidth: 1.25
}), h = Object.freeze({
  width: 16,
  height: 16,
  className: "min-w-4 min-h-4",
  viewbox: "0 0 24 24",
  strokeWidth: 1.5
}), a = Object.freeze({
  width: 20,
  height: 20,
  className: "min-w-5 min-h-5",
  viewbox: "0 0 24 24",
  strokeWidth: 1.75
}), c = Object.freeze({
  width: 24,
  height: 24,
  className: "min-w-6 min-h-6",
  viewbox: "0 0 24 24",
  strokeWidth: 1.75
}), n = Object.freeze({
  width: 32,
  height: 32,
  className: "min-w-8 min-h-8",
  viewbox: "0 0 24 24",
  strokeWidth: 1.75
}), s = Object.freeze({
  XS: i,
  S: h,
  M: a,
  L: c,
  XL: n
});
export {
  t as A,
  s as a,
  o as d
};
