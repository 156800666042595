import React from 'react'

import Welcome from './welcome'
import Chat from './chat'

import { useAppDispatch } from '../../hooks/redux'
import { actions as onboardingActions } from '../../store/slices/onboarding'

// ***** Types *****

type OnboardingPage = 'welcome' | 'chat'

const Onboarding: React.FC = () => {
  const dispatch = useAppDispatch()
  const [page, setPage] = React.useState<OnboardingPage>('welcome')

  if (page === 'welcome') {
    return (
      <Welcome
        next={(): void => { setPage('chat') }}
      />
    )
  }

  if (page === 'chat') {
    return (
      <Chat
        back={(): void => { setPage('welcome') }}
        next={(): void => {
          dispatch(onboardingActions.setOnboardingShow(false))
        }}
      />
    )
  }

  return null
}

export default Onboarding
