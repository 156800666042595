import '@gojasper/brand-standards/fonts.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { isNonEmptyString, isPlatform } from './type-guards'

import UniversalSidebar from './app'

// ***** Constants *****

const parameters = new URLSearchParams(window.location.search)
const platform = parameters.get('platform') ?? ''
const version = parameters.get('version') ?? '0.0.0'

// Always make sure the "platform" parameter is valid...
if (!isPlatform(platform)) throw new Error(`Platform parameter "${platform}" isn't supported.`)

// Always make sure the "version" parameter is valid...
if (!isNonEmptyString(version)) throw new Error(`Version parameter "${version as string}" isn't supported.`)

// ***** Render the app *****

const container = document.getElementById('app')
if (!container) throw new Error('App container not found.')

const root = ReactDOM.createRoot(container)
root.render(
  <UniversalSidebar
    platform={platform}
    version={version}
  />
)
