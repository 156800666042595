import React from 'react'
import { AnalyticsEventName, ModalName } from '@gojasper/atlas-tracking-sdk'
import { Button } from '@gojasper/base-ui/ava'

import Modal from '../../components/modal'
import JasperHead from '../../components/icons/jasper-head'

import Analytics from '../../utils/analytics'
import { TEST_IDS } from '../../test/test-ids'

// ***** Types *****

interface Props {
  readonly next: () => void
}

const Welcome: React.FC<Props> = ({ next }) => {
  React.useEffect(() => {
    Analytics.track(AnalyticsEventName.MODAL_VIEWED, {
      modalName: ModalName.ADD_IN_ONBOARDING_WELCOME
    })
  }, [])

  return (
    <Modal
      icon={<JasperHead style={{ width: 100 }} />}
      primaryText='Welcome to Jasper'
      secondaryText='Thanks for installing our add-on! Let&apos;s take a look at how it works.'
      data-testid={TEST_IDS.ONBOARDING_WELCOME_MODAL}
    >
      <Button text='Start tour' variant='primary' onClick={next} testIdOverride={TEST_IDS.ONBOARDING_START_TOUR_BUTTON} />
    </Modal>
  )
}

export default Welcome
