import { DEFAULT_FUNCTIONALITIES_CONFIGURATION } from '../constants'
import type {
  User,
  Workspace,
  WorkspaceProject,
  WorkspaceSubscription
} from '../types'
import type {
  AccountState,
  AuthenticationState,
  ConfigState,
  EntitlementsState,
  LaunchDarklyState,
  OnboardingState,
  RequestState,
  Requestable,
  RootState
} from './types'

export const DEFAULT_REQUESTABLE: Requestable = {
  pending: false,
  success: false,
  failed: false,
  failedMessage: '',
  time: 0
}

const DEFAULT_PROJECT: WorkspaceProject = {
  id: '',
  name: ''
}

const DEFAULT_SUBSCRIPTION: WorkspaceSubscription = {
  id: '',
  delinquent: false,
  paused: false,
  trialing: false,
  product: '',
  productKey: ''
}

const DEFAULT_WORKSPACE: Workspace = {
  id: '',
  active: false,
  domain: '',
  locked: false,
  name: '',
  customerId: '',
  promoId: '',
  permissions: { role: 'member' },
  project: DEFAULT_PROJECT,
  subscription: DEFAULT_SUBSCRIPTION,
  addonSubscriptions: [],
  firstActivated: '',
  workspaceUserCount: { aggregate: { count: 0 } },
  created_at: ''
}

const DEFAULT_USER: User = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  workspaceCount: 0,
  workspace: DEFAULT_WORKSPACE,
  created_at: ''
}

// ***** Initial state value *****

export const DEFAULT_CONFIG_STATE: ConfigState = {
  platform: 'microsoft-office-add-in',
  version: '0.0.0',
  functionalities: DEFAULT_FUNCTIONALITIES_CONFIGURATION
}

export const DEFAULT_REQUEST_STATE: RequestState = {
  'fetch-user': DEFAULT_REQUESTABLE,
  'fetch-entitlements': DEFAULT_REQUESTABLE,
  'fetch-oauth-code': DEFAULT_REQUESTABLE,
  'fetch-oauth-token': DEFAULT_REQUESTABLE,
  'validate-identity-token': DEFAULT_REQUESTABLE
}

export const DEFAULT_AUTHENTICATION_STATE: AuthenticationState = {
  initialized: false,
  idToken: '',
  oauthCode: '',
  oauthExpiry: 0
}

export const DEFAULT_ACCOUNT_STATE: AccountState = {
  user: DEFAULT_USER
}

export const DEFAULT_ENTITLEMENTS_STATE: EntitlementsState = {
  list: []
}

export const DEFAULT_ONBOARDING_STATE: OnboardingState = {
  show: true
}

export const DEFAULT_LAUNCH_DARKLY_STATE: LaunchDarklyState = {
  initialized: false,
  flags: {
    easyButtonUniversalSidebar: false
  }
}

export const DEFAULT_ROOT_STATE: RootState = {
  config: DEFAULT_CONFIG_STATE,
  request: DEFAULT_REQUEST_STATE,
  authentication: DEFAULT_AUTHENTICATION_STATE,
  account: DEFAULT_ACCOUNT_STATE,
  entitlements: DEFAULT_ENTITLEMENTS_STATE,
  onboarding: DEFAULT_ONBOARDING_STATE,
  launchDarkly: DEFAULT_LAUNCH_DARKLY_STATE
}
