import React from 'react'
import { AnalyticsEventName, ModalName } from '@gojasper/atlas-tracking-sdk'

import Modal from '../../components/modal'
import { useAppSelector } from '../../hooks/redux'
import { getOAuthCode, getOAuthLink } from '../../store/selectors'

import Analytics from '../../utils/analytics'
import { TEST_IDS } from '../../test/test-ids'
import { Icon, IconSizes, IconStyles } from '@gojasper/brand-standards/icons'
import LockUnlocked from '@gojasper/brand-standards/icons/LockUnlocked'
import { ColorsEnum } from '@gojasper/brand-standards'

export const OAuth: React.FC = () => {
  const code = useAppSelector(getOAuthCode)
  const url = useAppSelector(getOAuthLink)
  const { origin, host, pathname } = new URL(url)

  React.useEffect(() => {
    Analytics.track(AnalyticsEventName.MODAL_VIEWED, { modalName: ModalName.ADD_IN_OAUTH })
  }, [])

  return (
    <Modal
      icon={
        <Icon
          Component={LockUnlocked}
          size={IconSizes.XL}
          style={IconStyles.Line}
          color={ColorsEnum.Indigo700} />
      }
      primaryText='Sign in / up to Jasper'
      data-testid={TEST_IDS.AUTH_OAUTH_MODAL}
    >
      <div className='flex flex-col items-center justify-center space-y-[15px] text-sm'>
        <p className='text-center'>
          A new browser window should open, if it doesn't{' '}
          <a
            href={url}
            target='_blank'
            rel='noreferrer'
            className='text-indigo-700 transition-colors hover:text-indigo-500'
            data-testid={TEST_IDS.AUTH_OAUTH_LINK}
          >
            click here
          </a> or follow these steps:
        </p>

        <ol className='list-decimal list-inside'>
          <li>
            Head to{' '}
            <a
              href={`${origin}${pathname}`}
              target='_blank'
              rel='noreferrer'
              className='text-indigo-700 transition-colors hover:text-indigo-500'
            >
              {host}{pathname}
            </a>
          </li>
          <li data-testid={TEST_IDS.AUTH_OAUTH_CODE}>
            Enter the code: {code}
          </li>
        </ol>
      </div>
    </Modal>
  )
}
