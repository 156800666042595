import { camelCase } from 'lodash-es'
import type { LaunchDarklyFlags, RawLaunchDarklyFlags } from '../types'

/**
 * Gets an object of Launch-Darkly flags, formatted for analytics
 * purposes.
 *
 * @param flags Object of Launch-Darkly flags.
 * @returns Returns an object of formatted Launch-Darkly flags.
 */
const getFormattedLaunchDarklyFlags = (
  flags: LaunchDarklyFlags
): RawLaunchDarklyFlags =>
  Object.entries(flags).reduce<RawLaunchDarklyFlags>((acc, [key, entry]) => {
    // Skips flag if its an object (as the beta-app does)...
    if (typeof entry === 'object') return acc
    // Transforms key with a prefix...
    else acc = { ...acc, [`ldFlag_${camelCase(key)}`]: entry }

    return acc
  }, {})

export default getFormattedLaunchDarklyFlags
