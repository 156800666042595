import { GraphQLClient, gql } from 'graphql-request'

import { name, version } from '../../package.json'
import { GRAPHQL_URL } from '../constants'
import Datadog from './datadog'
import getSecurityHeaders from './get-security-headers'

import { getAuthenticationIdToken } from '../store/selectors'

import type { AppThunkAction } from '../store/types'

/*
  --- Why are we using graphql-request instead of Apollo? ---
  We are using graphql-request instead of Apollo because we only need a lightweight
  solution of using GraphQL for the Universal Sidebar, considering we believe it
  won't have have any standalone features besides for using iframes. This may change
  in the future and we can change this when the time comes.
*/

// ***** Constants *****

const client = new GraphQLClient(GRAPHQL_URL, {
  /**
   * Refer to the documentation for more information:
   *
   * @link https://github.com/jasonkuhrt/graphql-request?tab=readme-ov-file#all
   */
  errorPolicy: 'all'
})

const GET_ENTITLEMENTS_QUERY = gql`
query Web_GetEntitlements {
  viewer {
    id
    entitlements {
      feature
      value
    }
  }
}
`

const GET_OAUTH_CODE_QUERY = gql`
query Web_GetOAuthCode {
  oauthCode {
    code
    expiry
  }
}
`

const GET_OAUTH_TOKEN_QUERY = gql`
query Web_OAuthToken($code: String!) {
  oauthToken(code: $code) {
    token
  }
}
`

const QUERIES = {
  getEntitlements: GET_ENTITLEMENTS_QUERY,
  getOAuthCode: GET_OAUTH_CODE_QUERY,
  getOAuthToken: GET_OAUTH_TOKEN_QUERY
}

// ***** Types *****

type Query = keyof typeof QUERIES

/**
 * Helper function to perform a GraphQL request.
 *
 * Note this method doesn't validate the returned value. Which means the return
 * type will always be `unknown`. Consumer is responsible for validating the value.
 *
 * @param queryName Name of the query to perform.
 * @param variables Variables to pass to the query. Optional.
 * @returns The parsed version of the returned value. Or throw if the
 * request failed.
 * @throws the GraphQL error if the request does not succeed
 */
export const requestGraphQL = (
  queryName: Query,
  variables?: any // TODO: improve typing of GraphQL requests.
): AppThunkAction<Promise<unknown>> =>
  async (_, getState): Promise<unknown> => {
    // Retrieves the latest user token.
    const state = getState()
    const token = getAuthenticationIdToken(state)

    // Makes sure the query is defined.
    const query = QUERIES[queryName]
    if (!query) throw new Error(`Unknown query: ${queryName}`)

    try {
      // Performs the GraphQL call.
      const response = await client.rawRequest(query, variables, {
        Authorization: token ? `Bearer ${token}` : '',
        ...(token ? {} : await getSecurityHeaders()),
        'x-hasura-role': 'user',
        'apollographql-client-name': name,
        'apollographql-client-version': version
      })

      // We got an error from the server...
      if (response.errors) {
        const message = response.errors.map((e) => e.message).join('\n')
        throw new Error(message || 'Unknown error')
      }

      // ...or we got a successful response.
      return response.data
    } catch (e) {
      Datadog.error({
        error: e instanceof Error ? e : undefined,
        message: 'GraphQL request failed'
      })

      throw new Error('Unknown error')
    }
  }
