import React from 'react'
import { AnalyticsEventName, ModalName } from '@gojasper/atlas-tracking-sdk'
import { Button } from '@gojasper/base-ui/ava'
import { Icon, IconSizes, IconStyles } from '@gojasper/brand-standards/icons'
import { MessageChat } from '@gojasper/brand-standards/icons/MessageChat'

import Modal from '../../components/modal'
import Analytics from '../../utils/analytics'
import { TEST_IDS } from '../../test/test-ids'

// ***** Types *****

interface Props {
  readonly back: () => void
  readonly next: () => void
}

const Chat: React.FC<Props> = ({ back, next }) => {
  React.useEffect(() => {
    Analytics.track(AnalyticsEventName.MODAL_VIEWED, {
      modalName: ModalName.ADD_IN_ONBOARDING_CHAT
    })
  }, [])

  return (
    <Modal
      icon={<Icon Component={MessageChat} size={IconSizes.XL} style={IconStyles.Line} />}
      primaryText='Jasper Chat'
      secondaryText='Ask your friendly AI chatbot to generate ideas, revise your content, and even make you laugh.'
      data-testid={TEST_IDS.ONBOARDING_CHAT_MODAL}
    >
      <div className='flex flex-row space-x-4'>
        <Button fluid text='Back' variant='secondary' onClick={back} testIdOverride={TEST_IDS.ONBOARDING_BACK_BUTTON} />
        <Button fluid text='Next' variant='primary' onClick={next} testIdOverride={TEST_IDS.ONBOARDING_NEXT_BUTTON} />
      </div>
    </Modal>
  )
}

export default Chat
