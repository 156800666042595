import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_ONBOARDING_STATE } from '../constants'
import { setOnboardingStatus } from '../../utils/get-onboarding-status'

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: DEFAULT_ONBOARDING_STATE,
  reducers: {
    /**
     * Sets the status of the onboarding.
     */
    setOnboardingShow: (state, action: PayloadAction<boolean>) => {
      // Save the status in the store...
      state.show = action.payload

      // Save the status in local-storage for later session...
      setOnboardingStatus(action.payload)
    }
  }
})

export const { actions, reducer } = onboardingSlice
