import React from 'react'

// ***** Types *****

type Props = React.HTMLAttributes<HTMLOrSVGElement>

const JasperHead: React.FC<Props> = () => (
  <svg width={24} height={24} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3639 7.32996L19.1305 7.74757C19.4796 7.93455 19.6665 8.32723 19.598 8.70744L18.9061 12.6093C18.8313 13.0206 18.501 13.3261 18.0896 13.3635L16.9864 13.4694C16.8866 13.5816 16.7807 13.6876 16.6747 13.7873C15.7709 14.6474 14.5742 15.2458 13.203 15.6011C12.1558 15.8691 11.009 16 9.806 16C9.65018 16 9.48812 16 9.3323 15.9938C7.75536 15.9501 6.29062 15.6697 5.04403 15.1399C4.11532 14.7472 3.31126 14.2111 2.6755 13.5255L1.50371 13.3822C1.1048 13.3323 0.780684 13.0331 0.712122 12.6342L0.0140306 8.71367C-0.054532 8.32723 0.132457 7.93455 0.481502 7.74757L1.24816 7.32996C1.6533 5.37904 2.70667 3.66498 4.17141 2.41839C4.64512 2.01948 5.15622 1.67043 5.70472 1.37748V0.891313C5.70472 0.486171 6.96378 0.137125 8.68407 0.0311648C9.04558 0.0124659 9.41956 0 9.806 0C10.903 0 11.9003 0.0934943 12.6358 0.249318C13.4211 0.411375 13.9073 0.641995 13.9073 0.897546V1.38372C14.7238 1.82002 15.4593 2.37476 16.0951 3.03545C17.2232 4.20101 18.0273 5.67822 18.3639 7.32996ZM4.86955 12.6155C6.01642 13.432 7.72425 13.8621 9.80605 13.8621C11.8879 13.8621 13.5957 13.432 14.755 12.6155C15.8707 11.8177 16.413 10.6708 16.413 9.09387C16.413 5.45382 13.4523 2.49316 9.81229 2.49316C6.17224 2.49316 3.21159 5.45382 3.21159 9.09387C3.21159 10.6708 3.75386 11.8239 4.86955 12.6155Z"
        fill="#2F2B23"
      />
      <path
        id="Vector"
        d="M9.80611 12.3415C8.50966 12.3415 7.3067 11.5811 6.7395 10.4093L7.78664 9.91064C8.16685 10.696 8.93973 11.1884 9.80611 11.1884C10.6725 11.1884 11.4516 10.7022 11.8256 9.91064L12.8727 10.4093C12.3055 11.5811 11.1026 12.3415 9.80611 12.3415Z"
        fill="#2F2B23"
      />
    </g>
  </svg>

)

export default JasperHead
