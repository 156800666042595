import React from 'react'

import JasperHead from './icons/jasper-head'
import JasperTitle from './icons/jasper-title'

import { useAppDispatch, useAppSelector } from '../hooks/redux'
import { signOut } from '../store/thunks'
import { getUser } from '../store/selectors'

import { isNode } from '../type-guards'
import { Icon } from '@gojasper/brand-standards/icons'
import { LogOut } from '@gojasper/brand-standards/icons/LogOut'
import { ColorsEnum } from '@gojasper/brand-standards'

// ***** Constants *****

const DEFAULT_USER_NAME = 'Jasper'

const Header: React.FC = () => {
  const [settingsOpen, setSettingsOpen] = React.useState(false)
  const settingsRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useAppDispatch()
  const { firstName, lastName, email } = useAppSelector(getUser)

  /**
   * Handle clicking outside of the settings menu. If the click target is not
   * the settings menu, then close the settings menu.
   *
   * @param event The click event.
   * @returns void
   */
  const handleClickOutside = React.useCallback((event: MouseEvent): void => {
    if (
      settingsRef.current &&
      isNode(event.target) &&
      !settingsRef.current.contains(event.target)
    ) {
      setSettingsOpen(false)
    }
  }, [settingsRef])

  /**
   * Handle the window losing focus. If the active element is an iframe, then
   * close the settings menu.
   *
   * @returns void
   */
  const handleCheckClickedIframe = React.useCallback((): void => {
    if (document.activeElement === document.querySelector('iframe')) {
      setSettingsOpen(false)
    }
  }, [])

  /**
   * Handle the settings button being clicked. This function will stop the event
   * from propagating to the document, and will toggle the settings menu open
   * or closed.
   *
   * @param event The click event.
   * @returns void
   */
  const handleSettingsClick = React.useCallback((event: React.MouseEvent): void => {
    event.stopPropagation()
    setSettingsOpen((state) => !state)
  }, [])

  /**
   * Setup event listeners for clicking outside of the settings menu, and for
   * clicking outside of the iframe.
   */
  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    window.addEventListener('blur', handleCheckClickedIframe)

    return () => {
      document.removeEventListener('click', handleClickOutside)
      window.removeEventListener('blur', handleCheckClickedIframe)
    }
  }, [handleClickOutside, handleCheckClickedIframe])

  return (
    <div className='w-full h-[42px] z-50 p-[12px] flex items-center justify-between mb-0 min-[408px]:mb-[-12px]'>
      {/* Jasper Icon + Name */}
      <div className='flex items-center gap-2'>
        <JasperHead />
        <JasperTitle className='h-[15px] mt-1' />
      </div>

      <div className='relative h-full'>
        {/* Settings button */}
        <button
          className='block h-[18px] w-[18px] rounded-[3px] bg-green-500 bg-opacity-50 hover:bg-opacity-90 transition-colors text-white text-[8px] font-normal'
          onClick={handleSettingsClick}
        >
          {(firstName[0] ?? lastName[0] ?? DEFAULT_USER_NAME[0]).toUpperCase()}
        </button>

        {/* Settings menu */}
        <div
          ref={settingsRef}
          className={settingsOpen ? 'absolute overflow-hidden w-48 right-0 mt-2 flex flex-col justify-center bg-white shadow-md ring-1 ring-black ring-opacity-5 rounded-md' : 'hidden'}
        >
          <div className='px-4 py-2 bg-khaki-50'>
            <div className='font-medium leading-5 tracking-tight text-khaki-800 truncate'>{firstName} {lastName}</div>
            <div className='text-sm text-khaki-600 truncate'>{email}</div>
          </div>

          <button
            className="flex items-center gap-2 hover:bg-khaki-100 px-4 py-2 transition-colors text-sm text-khaki-700"
            onClick={(): void => void dispatch(signOut())}
          >
            <Icon Component={LogOut} color={ColorsEnum.Khaki700} />
            <div>Sign out</div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
