/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import { Provider } from 'react-redux'
import createStore from './store/create-store'

import ErrorBoundary from './containers/error-boundary'
import Main from './containers/main'

import Datadog from './utils/datadog'
import Analytics from './utils/analytics'
import LaunchDarkly from './utils/launch-darkly'
import IframeCommunicator from './utils/iframe-communicator'
import Firebase from './utils/firebase'

import { getOnboardingStatus } from './utils/get-onboarding-status'
import { DEFAULT_CONFIG_STATE } from './store/constants'

import type { AppStore } from './store/types'
import type { Platform } from './types'

/**
 * Universal-sidebar interface.
 */
interface Props {
  readonly platform: Platform
  readonly version: string
}

class UniversalSidebar extends React.Component<Props> {
  private readonly store: AppStore

  /**
   * Initialize the app.
   */
  public constructor (props: Props) {
    super(props)

    /**
     * Retrieve the status of the onboarding from local-storage.
     */
    const onboarding = getOnboardingStatus()

    /**
     * Initialize the Redux store with default values.
     */
    this.store = createStore({
      config: {
        ...DEFAULT_CONFIG_STATE,
        ...this.props
      },
      onboarding: { show: onboarding }
    })

    /**
     * Initialize the Datadog class.
     */
    Datadog.init(this.store)

    /**
     * Initialize the Analytics class.
     */
    Analytics.init(this.store)

    /**
     * Initialize the Launch-Darkly class.
     */
    LaunchDarkly.init(this.store)

    /**
     * Initialize the Iframe-communicator class.
     */
    IframeCommunicator.init(this.store)

    /**
     * Initialize the Firebase class.
     */
    Firebase.init(this.store)
  }

  public render (): JSX.Element {
    return (
      <ErrorBoundary>
        <Provider store={this.store}>
          <div className='w-screen h-screen flex items-center justify-center bg-khaki-50'>
            <Main />
          </div>
        </Provider>
      </ErrorBoundary>
    )
  }
}

export default UniversalSidebar
