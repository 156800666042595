import { jsx as o, jsxs as s } from "react/jsx-runtime";
import { A as n } from "../consts-3d3b7db3.mjs";
import { I as t } from "../types-ac618773.mjs";
import { C as e } from "../color-a1f65061.mjs";
const h = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: c,
    strokeLinecap: "square",
    strokeWidth: "1.25",
    d: "M4.084 5.25V4.083a2.917 2.917 0 0 1 5.59-1.166M7 8.167v1.166M4.2 12.25h5.6c.654 0 .98 0 1.23-.127.22-.112.398-.29.51-.51.127-.25.127-.576.127-1.23V7.117c0-.654 0-.98-.127-1.23a1.17 1.17 0 0 0-.51-.51c-.25-.127-.576-.127-1.23-.127H4.2c-.653 0-.98 0-1.23.127-.219.112-.397.29-.51.51-.127.25-.127.576-.127 1.23v3.266c0 .654 0 .98.128 1.23.112.22.29.398.51.51.25.127.576.127 1.23.127Z"
  }
) }), d = h, k = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ s("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: [
  /* @__PURE__ */ o(
    "path",
    {
      fill: c,
      d: "M2.334 9.033c0-1.12 0-1.68.217-2.108a2 2 0 0 1 .875-.874c.427-.218.987-.218 2.108-.218h2.933c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H5.533c-1.12 0-1.68 0-2.107-.218a2 2 0 0 1-.875-.874c-.218-.428-.218-.987-.218-2.108z",
      opacity: ".12"
    }
  ),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: c,
      strokeLinecap: "square",
      strokeWidth: "1.25",
      d: "M4.084 5.833V4.667A2.917 2.917 0 0 1 9.674 3.5M7 8.75v1.167m-2.8 2.916h5.6c.654 0 .98 0 1.23-.127.22-.112.398-.29.51-.51.127-.25.127-.576.127-1.23V7.7c0-.653 0-.98-.127-1.23a1.17 1.17 0 0 0-.51-.51c-.25-.127-.576-.127-1.23-.127H4.2c-.653 0-.98 0-1.23.127-.219.112-.397.29-.51.51-.127.25-.127.577-.127 1.23v3.267c0 .653 0 .98.128 1.23.112.219.29.397.51.51.25.126.576.126 1.23.126Z"
    }
  )
] }), w = k, v = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: c,
    strokeLinecap: "square",
    strokeWidth: "1.5",
    d: "M4.667 6V4.667a3.333 3.333 0 0 1 6.389-1.334M8 9.333v1.334M4.8 14h6.4c.747 0 1.12 0 1.405-.145.251-.128.455-.332.583-.583.145-.285.145-.659.145-1.405V8.133c0-.746 0-1.12-.145-1.405a1.33 1.33 0 0 0-.583-.583C12.32 6 11.947 6 11.2 6H4.8c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.145.285-.145.659-.145 1.405v3.734c0 .746 0 1.12.145 1.405.128.25.332.455.583.583C3.68 14 4.053 14 4.8 14Z"
  }
) }), p = v, u = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ s("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: [
  /* @__PURE__ */ o(
    "path",
    {
      fill: c,
      d: "M2.667 9.867c0-1.12 0-1.68.217-2.108a2 2 0 0 1 .875-.874c.427-.218.987-.218 2.108-.218h4.266c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.427.218.988.218 2.108v1.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H5.867c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.875-.874c-.217-.428-.217-.988-.217-2.108z",
      opacity: ".12"
    }
  ),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: c,
      strokeLinecap: "square",
      strokeWidth: "1.5",
      d: "M4.667 6.667V5.333A3.333 3.333 0 0 1 11.056 4M8 10v1.333m-3.2 3.334h6.4c.747 0 1.12 0 1.405-.146.251-.128.455-.332.583-.582.145-.286.145-.659.145-1.406V8.8c0-.747 0-1.12-.145-1.405a1.33 1.33 0 0 0-.583-.583c-.285-.145-.658-.145-1.405-.145H4.8c-.747 0-1.12 0-1.405.145-.251.128-.455.332-.583.583-.145.285-.145.658-.145 1.405v3.733c0 .747 0 1.12.145 1.406.128.25.332.454.583.582.285.146.658.146 1.405.146Z"
    }
  )
] }), C = u, g = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: c,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "M5.834 7.5V5.833a4.167 4.167 0 0 1 7.986-1.666m-3.82 7.5v1.666M6 17.5h8c.934 0 1.4 0 1.757-.182.313-.16.568-.415.728-.728.182-.357.182-.823.182-1.757v-4.666c0-.934 0-1.4-.182-1.757a1.67 1.67 0 0 0-.728-.728C15.4 7.5 14.934 7.5 14 7.5H6c-.933 0-1.4 0-1.756.182-.314.16-.57.414-.729.728-.182.356-.182.823-.182 1.757v4.666c0 .934 0 1.4.182 1.757.16.314.415.569.729.728.356.182.823.182 1.756.182Z"
  }
) }), L = g, m = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ s("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: [
  /* @__PURE__ */ o(
    "path",
    {
      fill: c,
      d: "M3.334 11.533c0-1.12 0-1.68.217-2.108a2 2 0 0 1 .875-.874c.427-.218.987-.218 2.108-.218h6.933c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v3.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874c-.428.218-.988.218-2.108.218H6.533c-1.12 0-1.68 0-2.107-.218a2 2 0 0 1-.875-.874c-.218-.427-.218-.988-.218-2.108z",
      opacity: ".12"
    }
  ),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: c,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "M5.834 8.333V6.667A4.167 4.167 0 0 1 13.82 5M10 12.5v1.667m-4 4.166h8c.934 0 1.4 0 1.757-.181.313-.16.568-.415.728-.729.182-.356.182-.823.182-1.756V11c0-.933 0-1.4-.182-1.757a1.67 1.67 0 0 0-.728-.728c-.357-.182-.823-.182-1.757-.182H6c-.933 0-1.4 0-1.756.182-.314.16-.57.415-.729.728-.182.357-.182.824-.182 1.757v4.667c0 .933 0 1.4.182 1.756.16.314.415.569.729.729.356.181.823.181 1.756.181Z"
    }
  )
] }), M = m, f = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: c,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "M7 9V7a5 5 0 0 1 9.584-2M12 14v2m-4.8 5h9.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 19.48 20 18.92 20 17.8v-5.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C18.48 9 17.92 9 16.8 9H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C4 10.52 4 11.08 4 12.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C5.52 21 6.08 21 7.2 21Z"
  }
) }), r = f, V = ({ color: c = e.CurrentColor }) => /* @__PURE__ */ s("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: [
  /* @__PURE__ */ o(
    "path",
    {
      fill: c,
      d: "M4 13.2c0-1.12 0-1.68.218-2.108a2 2 0 0 1 .874-.874C5.52 10 6.08 10 7.2 10h9.6c1.12 0 1.68 0 2.108.218a2 2 0 0 1 .874.874C20 11.52 20 12.08 20 13.2v5.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.48 22 17.92 22 16.8 22H7.2c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C4 20.48 4 19.92 4 18.8z",
      opacity: ".12"
    }
  ),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: c,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "M7 10V8a5 5 0 0 1 9.584-2M12 15v2m-4.8 5h9.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 20.48 20 19.92 20 18.8v-5.6c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C18.48 10 17.92 10 16.8 10H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C4 11.52 4 12.08 4 13.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C5.52 22 6.08 22 7.2 22Z"
    }
  )
] }), l = V, H = {
  [n.XS]: {
    [t.Line]: (c) => /* @__PURE__ */ o(d, { color: c }),
    [t.Duo]: (c) => /* @__PURE__ */ o(w, { color: c }),
    [t.Filled]: (c) => null
  },
  [n.S]: {
    [t.Line]: (c) => /* @__PURE__ */ o(p, { color: c }),
    [t.Duo]: (c) => /* @__PURE__ */ o(C, { color: c }),
    [t.Filled]: (c) => null
  },
  [n.M]: {
    [t.Line]: (c) => /* @__PURE__ */ o(L, { color: c }),
    [t.Duo]: (c) => /* @__PURE__ */ o(M, { color: c }),
    [t.Filled]: (c) => null
  },
  [n.L]: {
    [t.Line]: (c) => /* @__PURE__ */ o(r, { color: c }),
    [t.Duo]: (c) => /* @__PURE__ */ o(l, { color: c }),
    [t.Filled]: (c) => null
  },
  [n.XL]: {
    [t.Line]: (c) => /* @__PURE__ */ o(r, { color: c }),
    [t.Duo]: (c) => /* @__PURE__ */ o(l, { color: c }),
    [t.Filled]: (c) => null
  }
}, W = ({ size: c, style: i, color: a }) => H[c][i](a);
export {
  W as LockUnlocked,
  W as default
};
