import { C as a } from "./color-a1f65061.mjs";
const e = 16;
var l = ((r) => (r[r.Caption = `${e * 0.75}px`] = "Caption", r[r.Small = `${e * 0.875}px`] = "Small", r[r.Body = `${e * 1}px`] = "Body", r[r.H5 = `${e * 0.75}px`] = "H5", r[r.H4 = `${e * 1.3125}px`] = "H4", r[r.H3 = `${e * 1.75}px`] = "H3", r[r.H2 = `${e * 2.375}px`] = "H2", r[r.H1 = `${e * 3.125}px`] = "H1", r))(l || {}), p = ((r) => (r[r.Tag = `${e * 0.625}px`] = "Tag", r))(p || {}), t = /* @__PURE__ */ ((r) => (r[r.Caption = `${0.75}rem`] = "Caption", r[r.Small = `${0.875}rem`] = "Small", r.Body = "1rem", r[r.H5 = `${0.75}rem`] = "H5", r[r.H4 = `${1.3125}rem`] = "H4", r[r.H3 = `${1.75}rem`] = "H3", r[r.H2 = `${2.375}rem`] = "H2", r[r.H1 = `${3.125}rem`] = "H1", r))(t || {}), i = /* @__PURE__ */ ((r) => (r[r.Tag = `${0.625}rem`] = "Tag", r))(i || {}), d = /* @__PURE__ */ ((r) => (r.Caption = "1rem", r[r.Small = `${1.5}rem`] = "Small", r[r.Body = `${1.75}rem`] = "Body", r.H5 = "1rem", r.H4 = "2rem", r[r.H3 = `${2.625}rem`] = "H3", r[r.H2 = `${3.125}rem`] = "H2", r[r.H1 = `${3.5}rem`] = "H1", r))(d || {}), o = ((r) => (r[r.Caption = `${e * 1}px`] = "Caption", r[r.Small = `${e * 1.5}px`] = "Small", r[r.Body = `${e * 1.75}px`] = "Body", r[r.H5 = `${e * 1}px`] = "H5", r[r.H4 = `${e * 2}px`] = "H4", r[r.H3 = `${e * 2.625}px`] = "H3", r[r.H2 = `${e * 3.125}px`] = "H2", r[r.H1 = `${e * 3.5}px`] = "H1", r))(o || {}), c = ((r) => (r[r.Tag = `${e * 1}px`] = "Tag", r))(c || {});
const f = Object.freeze({
  caption: [
    t.Caption,
    "1rem"
    /* Caption */
  ],
  small: [t.Small, d.Small],
  body: ["1rem", d.Body],
  h5: [
    t.H5,
    "1rem"
    /* H5 */
  ],
  h4: [
    t.H4,
    "2rem"
    /* H4 */
  ],
  h3: [t.H3, d.H3],
  h2: [t.H2, d.H2],
  h1: [t.H1, d.H1]
}), s = Object.freeze({
  tag: [
    i.Tag,
    "1rem"
    /* Tag */
  ]
}), H = Object.freeze({
  caption: [l.Caption, o.Caption],
  small: [l.Small, o.Small],
  body: [l.Body, o.Body],
  h5: [l.H5, o.H5],
  h4: [l.H4, o.H4],
  h3: [l.H3, o.H3],
  h2: [l.H2, o.H2],
  h1: [l.H1, o.H1]
});
var n = /* @__PURE__ */ ((r) => (r[r.Thin = 200] = "Thin", r[r.Light = 300] = "Light", r[r.Regular = 400] = "Regular", r[r.Medium = 500] = "Medium", r[r.Bold = 600] = "Bold", r[r.Heavy = 700] = "Heavy", r[r.Black = 800] = "Black", r[r.Ultra = 900] = "Ultra", r))(n || {});
const k = Object.freeze({
  thin: 200,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  heavy: 700,
  black: 800,
  ultra: 900
  /* Ultra */
}), y = Object.freeze({
  // default sizes
  tighter: "-0.05em",
  tight: "-0.025em",
  normal: "0",
  wide: "0.025em",
  wider: "0.05em",
  widest: "0.1em",
  // custom sizes
  button: "0.01em"
}), b = Object.freeze({
  currentColor: a.CurrentColor,
  transparent: a.Transparent,
  black: a.Black,
  white: a.White,
  indigo: {
    50: a.Indigo050,
    100: a.Indigo100,
    200: a.Indigo200,
    300: a.Indigo300,
    400: a.Indigo400,
    500: a.Indigo500,
    600: a.Indigo600,
    700: a.Indigo700,
    800: a.Indigo800,
    900: a.Indigo900
  },
  green: {
    50: a.Green050,
    100: a.Green100,
    200: a.Green200,
    300: a.Green300,
    400: a.Green400,
    500: a.Green500,
    600: a.Green600,
    700: a.Green700,
    800: a.Green800,
    900: a.Green900
  },
  yellow: {
    50: a.Yellow050,
    100: a.Yellow100,
    200: a.Yellow200,
    300: a.Yellow300,
    400: a.Yellow400,
    500: a.Yellow500,
    600: a.Yellow600,
    700: a.Yellow700,
    800: a.Yellow800,
    900: a.Yellow900
  },
  red: {
    50: a.Red050,
    100: a.Red100,
    200: a.Red200,
    300: a.Red300,
    400: a.Red400,
    500: a.Red500,
    600: a.Red600,
    700: a.Red700,
    800: a.Red800,
    900: a.Red900
  },
  teal: {
    50: a.Teal050,
    100: a.Teal100,
    200: a.Teal200,
    300: a.Teal300,
    400: a.Teal400,
    500: a.Teal500,
    600: a.Teal600,
    700: a.Teal700,
    800: a.Teal800,
    900: a.Teal900
  },
  khaki: {
    25: a.Khaki025,
    50: a.Khaki050,
    100: a.Khaki100,
    150: a.Khaki150,
    200: a.Khaki200,
    300: a.Khaki300,
    400: a.Khaki400,
    500: a.Khaki500,
    600: a.Khaki600,
    700: a.Khaki700,
    800: a.Khaki800,
    900: a.Khaki900
  },
  orange: {
    50: a.Orange050,
    100: a.Orange100,
    200: a.Orange200,
    300: a.Orange300,
    400: a.Orange400,
    500: a.Orange500,
    600: a.Orange600,
    700: a.Orange700,
    800: a.Orange800,
    900: a.Orange900
  },
  pink: {
    50: a.Pink050,
    100: a.Pink100,
    200: a.Pink200,
    300: a.Pink300,
    400: a.Pink400,
    500: a.Pink500,
    600: a.Pink600,
    700: a.Pink700,
    800: a.Pink800,
    900: a.Pink900
  }
}), w = {
  colors: b,
  fontSize: { ...f, ...s },
  fontWeight: k,
  letterSpacing: y,
  extend: {
    spacing: {
      80: "20rem"
    },
    scale: {
      "-1": "-1"
    },
    animation: {
      blink: "blink 1s steps(2, start) infinite",
      "fade-in": "fade-in 0.3s forwards",
      "fade-out": "fade-out 0.3s forwards",
      "fade-in-output": "fade-in 1s cubic-bezier(0.22, 1, 0.36, 1) forwards",
      "fade-in-left": "fade-in-left 0.5s ease-out",
      "slide-in": "slide-in 0.5s cubic-bezier(0.22, 1, 0.36, 1) forwards",
      "reverse-spin": "reverse-spin 1s linear infinite",
      "point-right": "point-right 1.25s infinite;",
      hop: "hop 1s ease-in-out infinite",
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      "slide-down": "slide-down 400ms cubic-bezier(0.16, 1, 0.3, 1)",
      "slide-left": "slide-left 400ms cubic-bezier(0.16, 1, 0.3, 1)",
      "slide-up": "slide-up 400ms cubic-bezier(0.16, 1, 0.3, 1)",
      "slide-right": "slide-right 400ms cubic-bezier(0.16, 1, 0.3, 1)",
      "slide-in-x": "slide-in-x 0.2s ease-out",
      "slide-out-x": "slide-out-x 0.2s ease-in"
    },
    keyframes: {
      blink: {
        from: { visibility: "visible" },
        to: { visibility: "hidden" }
      },
      "fade-in": {
        from: { opacity: 0 },
        to: { opacity: 1 }
      },
      "fade-out": {
        from: { opacity: 1 },
        to: { opacity: 0 }
      },
      "fade-in-left": {
        "0%": {
          opacity: "0",
          transform: "translateX(50px)"
        },
        "100%": {
          opacity: "1",
          transform: "translateX(0)"
        }
      },
      "slide-in": {
        from: {
          transform: "translateX(-20px)",
          opacity: 0
        },
        to: {
          transform: "translateX(0)",
          opacity: 1
        }
      },
      "reverse-spin": {
        from: {
          transform: "rotate(360deg)"
        }
      },
      "point-right": {
        "0%, 100%": {
          transform: " translateX(-25%)"
        },
        "50%": {
          transform: " translateY(0)"
        }
      },
      hop: {
        "0%": {
          transform: "translateY(0)"
        },
        "50%": {
          transform: "translateY(-150%)"
        },
        "100%": {
          transform: "translateY(0)"
        }
      },
      "accordion-down": {
        from: { height: "0" },
        to: { height: "var(--radix-accordion-content-height)" }
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: "0" }
      },
      "slide-down": {
        from: { opacity: "0", transform: "translateY(-2px)" },
        to: { opacity: "1", transform: "translateY(0)" }
      },
      "slide-left": {
        from: { opacity: "0", transform: "translateX(2px)" },
        to: { opacity: "1", transform: "translateX(0)" }
      },
      "slide-up": {
        from: { opacity: "0", transform: "translateY(2px)" },
        to: { opacity: "1", transform: "translateY(0)" }
      },
      "slide-right": {
        from: { opacity: "0", transform: "translateX(-2px)" },
        to: { opacity: "1", transform: "translateX(0)" }
      },
      "slide-in-x": {
        "0%": { transform: "translateX(100%)" },
        "100%": { transform: "translateX(0)" }
      },
      "slide-out-x": {
        "0%": { transform: "translateX(0)" },
        "100%": { transform: "translateX(100%)" }
      }
    },
    maxWidth: {
      "8xl": "88rem",
      "9xl": "96rem"
    },
    boxShadow: {
      "profile-dropdown": "0px -8px 10px 0px rgba(0, 0, 0, 0.05)"
    },
    borderColor: {
      DEFAULT: a.Khaki200
    }
  }
};
export {
  a as ColorsEnum,
  n as FontWeightsEnum,
  s as customFontSize,
  w as defaultTheme,
  f as fontSize,
  H as fontSizePx,
  k as fontWeight,
  y as letterSpacing
};
