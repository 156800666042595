import { isBoolean, isNonEmptyString } from '../type-guards'

// ***** Constants *****

export const ONBOARDING_KEY = '@gojasper/onboarding'

/**
 * Gets the onboarding status.
 * @returns Returns `true` when the onboarding should show, else `false`.
 */
export const getOnboardingStatus = (): boolean => {
  try {
    const value = window.localStorage.getItem(ONBOARDING_KEY)
    if (!isNonEmptyString(value)) return true

    const parsed = JSON.parse(value)
    if (!isBoolean(parsed)) return true

    return parsed
  } catch (_) {
    return true
  }
}

/**
 * Sets the onboarding status.
 */
export const setOnboardingStatus = (value: boolean): void => {
  try {
    window.localStorage.setItem(ONBOARDING_KEY, JSON.stringify(value))
  } catch (_) {
    // Error saving in local-storage...
  }
}
