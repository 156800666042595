import React from 'react'

// ***** Types *****

type Props = React.HTMLAttributes<HTMLOrSVGElement>

const JasperTitle: React.FC<Props> = (props) => (
  <svg viewBox="0 0 77 21" fill="none" {...props}>
    <path d="M5.88723 16.1782C4.87387 16.1782 3.93614 15.9815 3.07403 15.5883C2.22704 15.1799 1.53131 14.6127 0.986816 13.8868L2.66566 11.8676C3.5429 13.0776 4.59407 13.6826 5.81917 13.6826C7.46777 13.6826 8.29206 12.707 8.29206 10.7559V2.54321V0.0703125H11.2414V10.5971C11.2414 12.4575 10.7876 13.8565 9.88016 14.7942C8.97268 15.7169 7.6417 16.1782 5.88723 16.1782Z" fill="currentColor"/>
    <path d="M18.8831 3.70025C20.6679 3.70025 22.0291 4.1313 22.9668 4.99341C23.9197 5.8404 24.3961 7.126 24.3961 8.85022V15.9513H21.719V14.4766C21.3712 15.006 20.872 15.4144 20.2217 15.7017C19.5864 15.974 18.8151 16.1101 17.9076 16.1101C17.0001 16.1101 16.2061 15.9588 15.5255 15.6564C14.8448 15.3387 14.3155 14.9077 13.9374 14.3632C13.5744 13.8036 13.3929 13.1759 13.3929 12.4802C13.3929 11.3912 13.7937 10.5215 14.5953 9.87114C15.412 9.20565 16.6901 8.87291 18.4294 8.87291H21.5602V8.69141C21.5602 7.84443 21.3031 7.19406 20.7889 6.74032C20.2897 6.28658 19.5411 6.05971 18.5428 6.05971C17.8622 6.05971 17.1892 6.16558 16.5237 6.37733C15.8733 6.58907 15.3213 6.88401 14.8675 7.26212L13.7559 5.1976C14.3911 4.71361 15.1549 4.34305 16.0473 4.08593C16.9396 3.82881 17.8849 3.70025 18.8831 3.70025ZM18.4975 14.0456C19.2083 14.0456 19.836 13.8868 20.3805 13.5691C20.9401 13.2364 21.3334 12.7675 21.5602 12.1625V10.7559H18.6336C17.0001 10.7559 16.1834 11.2929 16.1834 12.3667C16.1834 12.881 16.3876 13.2893 16.7959 13.5918C17.2043 13.8943 17.7715 14.0456 18.4975 14.0456Z" fill="currentColor"/>
    <path d="M31.779 16.1101C30.7959 16.1101 29.8355 15.9815 28.8977 15.7244C27.96 15.4673 27.2113 15.1421 26.6517 14.7489L27.7407 12.5936C28.2852 12.9566 28.9355 13.2515 29.6918 13.4784C30.4631 13.6901 31.2194 13.796 31.9605 13.796C33.6545 13.796 34.5014 13.3498 34.5014 12.4575C34.5014 12.034 34.2821 11.739 33.8435 11.5727C33.42 11.4063 32.7318 11.2475 31.779 11.0962C30.7808 10.945 29.964 10.7711 29.3288 10.5744C28.7087 10.3778 28.1642 10.0375 27.6953 9.55352C27.2416 9.0544 27.0147 8.36623 27.0147 7.48899C27.0147 6.33951 27.4911 5.42447 28.444 4.74386C29.412 4.04812 30.7127 3.70025 32.3462 3.70025C33.178 3.70025 34.0099 3.79856 34.8418 3.99518C35.6736 4.17668 36.3542 4.42624 36.8836 4.74386L35.7946 6.89913C34.7661 6.29414 33.6091 5.99165 32.3235 5.99165C31.4916 5.99165 30.8564 6.12021 30.4178 6.37733C29.9943 6.61932 29.7825 6.94451 29.7825 7.35287C29.7825 7.80662 30.0094 8.1318 30.4631 8.32842C30.932 8.50991 31.6504 8.68385 32.6184 8.85022C33.5864 9.00147 34.3804 9.1754 35.0006 9.37203C35.6207 9.56865 36.15 9.90139 36.5887 10.3703C37.0424 10.8391 37.2693 11.5046 37.2693 12.3667C37.2693 13.5011 36.7777 14.4086 35.7946 15.0892C34.8115 15.7698 33.473 16.1101 31.779 16.1101Z" fill="currentColor"/>
    <path d="M46.4269 3.70025C47.6066 3.70025 48.6578 3.95737 49.5804 4.47161C50.5181 4.98585 51.2517 5.71184 51.781 6.64957C52.3104 7.58731 52.5751 8.66873 52.5751 9.89383C52.5751 11.1189 52.3104 12.2079 51.781 13.1608C51.2517 14.0985 50.5181 14.8245 49.5804 15.3387C48.6578 15.853 47.6066 16.1101 46.4269 16.1101C44.7934 16.1101 43.5002 15.5656 42.5474 14.4766V20.3526H39.7115V3.83637H42.4113V5.42447C42.8801 4.84973 43.4549 4.41867 44.1355 4.1313C44.8312 3.84393 45.595 3.70025 46.4269 3.70025ZM46.1093 13.6826C47.1529 13.6826 48.0074 13.3347 48.6729 12.639C49.3535 11.9432 49.6938 11.0282 49.6938 9.89383C49.6938 8.75947 49.3535 7.84443 48.6729 7.14869C48.0074 6.45295 47.1529 6.10508 46.1093 6.10508C45.4286 6.10508 44.8161 6.26389 44.2716 6.58151C43.7271 6.88401 43.2961 7.32262 42.9784 7.89736C42.6608 8.4721 42.502 9.13759 42.502 9.89383C42.502 10.6501 42.6608 11.3156 42.9784 11.8903C43.2961 12.465 43.7271 12.9112 44.2716 13.2288C44.8161 13.5313 45.4286 13.6826 46.1093 13.6826Z" fill="currentColor"/>
    <path d="M66.6535 9.96189C66.6535 10.1585 66.6384 10.4383 66.6081 10.8013H57.1022C57.2686 11.6937 57.6996 12.4045 58.3954 12.9339C59.1063 13.4481 59.9835 13.7053 61.0271 13.7053C62.3581 13.7053 63.4546 13.2666 64.3167 12.3894L65.8368 14.1363C65.2923 14.7867 64.6041 15.2782 63.7722 15.611C62.9404 15.9437 62.0026 16.1101 60.959 16.1101C59.6281 16.1101 58.4559 15.8454 57.4425 15.316C56.4292 14.7867 55.6427 14.0531 55.0831 13.1154C54.5386 12.1625 54.2663 11.0887 54.2663 9.89383C54.2663 8.7141 54.531 7.65537 55.0604 6.71763C55.6049 5.76478 56.3535 5.02366 57.3064 4.4943C58.2593 3.96493 59.3331 3.70025 60.528 3.70025C61.7077 3.70025 62.7589 3.96493 63.6815 4.4943C64.6192 5.00854 65.3452 5.74209 65.8594 6.69495C66.3888 7.63268 66.6535 8.72166 66.6535 9.96189ZM60.528 5.96896C59.6205 5.96896 58.8491 6.2412 58.2139 6.7857C57.5938 7.31506 57.2157 8.02592 57.0795 8.91828H63.9537C63.8327 8.04105 63.4622 7.33019 62.8421 6.7857C62.2219 6.2412 61.4506 5.96896 60.528 5.96896Z" fill="currentColor"/>
    <path d="M72.0773 5.60597C72.894 4.33549 74.3309 3.70025 76.3879 3.70025V6.40001C76.1459 6.35464 75.9266 6.33195 75.7299 6.33195C74.6258 6.33195 73.7637 6.65714 73.1436 7.3075C72.5235 7.94274 72.2134 8.86535 72.2134 10.0753V15.9513H69.3775V3.83637H72.0773V5.60597Z" fill="#121826"/>
  </svg>
)

export default JasperTitle
