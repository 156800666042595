import { DEFAULT_CONFIG_STATE } from '../constants'
import { DEFAULT_FUNCTIONALITIES_CONFIGURATION } from '../../constants'

import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { FunctionalitiesConfiguration } from '../../universal-sidebar-communicator'

const configSlice = createSlice({
  name: 'config',
  initialState: DEFAULT_CONFIG_STATE,
  reducers: {
    /**
     * Sets functionalities configuration in the store.
     */
    setConfiguration: (state, action: PayloadAction<Partial<FunctionalitiesConfiguration>>) => {
      state.functionalities = {
        ...DEFAULT_FUNCTIONALITIES_CONFIGURATION,
        ...action.payload
      }
    }
  }
})

export const { actions, reducer } = configSlice
