import React from 'react'
import { twMerge } from 'tailwind-merge'

// ***** Types *****

type Props = React.HTMLAttributes<HTMLSpanElement>

// eslint-disable-next-line react/prop-types
const Error: React.FC<Props> = ({ className, children, ...props }) => (
  <span className={twMerge('text-red-700 font-normal text-small', className)} {...props}>
    {children}
  </span>
)

export default Error
