import { ENVIRONMENTS, PLATFORMS } from './constants'
import type { User, IdentityToken, Environment, Platform, CustomToken, Entitlement, FetchGraphQLEntitlements, FetchGraphQLOAuthCode, FetchGraphQLOAuthToken } from './types'

/*
 * Tells if a given input is a string (empty or non-empty).
 */
export const isString = (tbd: any): tbd is string => typeof tbd === 'string'

/**
 * Tells if a given input is a non-empty string.
 */
export const isNonEmptyString = (tbd: any): tbd is string => isString(tbd) && tbd !== ''

/**
 * Tells if a given input is an array.
 */
export const isArray = (tbd: any): tbd is any[] => Array.isArray(tbd)

/**
 * Tells if a given input is a boolean.
 */
export const isBoolean = (tbd: any): tbd is boolean => typeof tbd === 'boolean'

/**
 * Tells if a given input is a number.
 */
export const isNumber = (tbd: any): tbd is number => typeof tbd === 'number'

/**
 * Tells if a given input is a Node.
 */
export const isNode = (tbd: any): tbd is Node => tbd && !!tbd.nodeType

/**
 * Tells if a given input is of type Environment.
 */
export const isEnvironment = (tbd: any): tbd is Environment => (
  isNonEmptyString(tbd) && ENVIRONMENTS.includes(tbd as Environment)
)

/**
 * Tells if a given input is of type Platform.
 */
export const isPlatform = (tbd: any): tbd is Platform => (
  isNonEmptyString(tbd) && PLATFORMS.includes(tbd as Platform)
)

/**
 * Tells if a given input is an inactive User object.
 */
export const isInactiveUser = (tbd: any): tbd is User => (
  typeof tbd === 'object' &&
  isNumber(tbd.workspaceCount) &&
  tbd.workspaceCount === 0
)

/**
 * Tells if a given input is of type User.
 */
export const isUser = (tbd: any): tbd is User => (
  typeof tbd === 'object' &&
  isNonEmptyString(tbd.id)
)

/**
 * Tells if a given input is of type Entitlement.
 */
export const isEntitlement = (tbd: any): tbd is Entitlement => (
  typeof tbd === 'object' && isString(tbd.feature) && isNumber(tbd.value)
)

/**
 * Tells if a given input is of type Entitlement[].
 */
export const isEntitlementList = (tbd: any): tbd is Entitlement[] => (
  Array.isArray(tbd) && tbd.every(isEntitlement)
)

/**
 * Tells if a given input is of type FetchGraphQLEntitlements.
 */
export const isGraphQLEntitlements = (tbd: any): tbd is FetchGraphQLEntitlements => (
  typeof tbd === 'object' &&
  typeof tbd.viewer === 'object' &&
  isEntitlementList(tbd.viewer.entitlements)
)

/**
 * Tells if a given input is of type FetchGraphQLOAuthCode.
 */
export const isGraphQLOAuthCode = (tbd: any): tbd is FetchGraphQLOAuthCode => (
  typeof tbd === 'object' &&
  'oauthCode' in tbd &&
  isNonEmptyString(tbd.oauthCode.code) &&
  isNumber(tbd.oauthCode.expiry)
)

/**
 * Tells if a given input is of type FetchGraphQLOAuthToken.
 */
export const isGraphQLOAuthToken = (tbd: any): tbd is FetchGraphQLOAuthToken => (
  typeof tbd === 'object' &&
  'oauthToken' in tbd &&
  // Purposely not checking with `isNonEmptyString` as the token can be an empty string.
  isString(tbd.oauthToken.token)
)

/**
 * Tells if a given input is of type IdentityToken.
 */
export const isIdentityToken = (tbd: any): tbd is IdentityToken => (
  typeof tbd === 'object' && isNonEmptyString(tbd.identityToken)
)

/**
 * Tells if a given input is of type CustomToken.
 */
export const isCustomToken = (tbd: any): tbd is CustomToken => (
  typeof tbd === 'object' && isNonEmptyString(tbd.token)
)

/**
 * Tells if a given input Event is of type MessageEvent.
 */
export const isMessageEvent = (tbd: Event): tbd is MessageEvent => (
  tbd.type === 'message'
)
