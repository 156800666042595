import React from 'react'
import { AnalyticsEventName, ModalName } from '@gojasper/atlas-tracking-sdk'
import { Button } from '@gojasper/base-ui/ava'
import { Icon, IconSizes, IconStyles } from '@gojasper/brand-standards/icons'
import { LockUnlocked } from '@gojasper/brand-standards/icons/LockUnlocked'

import Modal from '../../components/modal'
import Error from '../../components/error'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { signIn } from '../../store/thunks'
import { getFetchOAuthCode, getFetchOAuthToken } from '../../store/selectors'

import Analytics from '../../utils/analytics'
import { TEST_IDS } from '../../test/test-ids'

const SignIn: React.FC = () => {
  const dispatch = useAppDispatch()
  const fetchOAuthCode = useAppSelector(getFetchOAuthCode)
  const fetchOAuthToken = useAppSelector(getFetchOAuthToken)

  React.useEffect(() => {
    Analytics.track(AnalyticsEventName.MODAL_VIEWED, { modalName: ModalName.ADD_IN_SIGN_IN })
  }, [])

  return (
    <Modal
      icon={<Icon Component={LockUnlocked} size={IconSizes.XL} style={IconStyles.Line} />}
      primaryText='Get started'
      secondaryText='Sign in to start using Jasper'
      data-testid={TEST_IDS.AUTH_SIGN_IN_MODAL}
    >
      <div className='flex flex-col items-center space-y-[10px]'>
        <Button
          text='Sign in'
          variant='primary'
          onClick={(): void => {
            void dispatch(signIn())
          }}
          testIdOverride={TEST_IDS.AUTH_SIGN_IN_BUTTON}
          loading={fetchOAuthCode.pending}
        />
        {(fetchOAuthCode.failed || fetchOAuthToken.failed) && (
          <Error data-testid={TEST_IDS.AUTH_SIGN_IN_ERROR}>
            {fetchOAuthCode.failedMessage || fetchOAuthToken.failedMessage || 'An error occurred while trying to sign in.'}
          </Error>
        )}
      </div>
    </Modal>
  )
}

export default SignIn
