import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_REQUEST_STATE } from '../constants'
import { clearStore } from '../actions'

import type { RequestName } from '../types'
import type {
  User,
  Entitlement,
  FetchGraphQLOAuthCode,
  FetchGraphQLOAuthToken
} from '../../types'
import { isString, isUser, isEntitlementList, isGraphQLOAuthCode, isGraphQLOAuthToken } from '../../type-guards'

// ***** Type-guards *****

export const isFetchBase = (tbd: any): tbd is FetchBase => (
  typeof tbd === 'object' && isString(tbd.request)
)

export const isFetchUser = (tbd: any): tbd is FetchUser => (
  isUser(tbd.data) && isFetchBase(tbd)
)

export const isFetchEntitlements = (tbd: any): tbd is FetchEntitlements => (
  isEntitlementList(tbd.data) && isFetchBase(tbd)
)

export const isFetchOAuthCode = (tbd: any): tbd is FetchOAuthCode => (
  typeof tbd === 'object' &&
  typeof tbd.data === 'object' &&
  isGraphQLOAuthCode(tbd.data) &&
  isFetchBase(tbd) &&
  tbd.request === 'fetch-oauth-code'
)

export const isFetchOAuthToken = (tbd: any): tbd is FetchOAuthToken => (
  typeof tbd === 'object' &&
  typeof tbd.data === 'object' &&
  isGraphQLOAuthToken(tbd.data) &&
  isFetchBase(tbd) &&
  tbd.request === 'fetch-oauth-token'
)

// ***** Types *****

interface FetchBase {
  readonly request: RequestName
}

interface FetchUser extends FetchBase {
  readonly request: 'fetch-user'
  readonly data: User
}

interface FetchEntitlements extends FetchBase {
  readonly request: 'fetch-entitlements'
  readonly data: Entitlement[]
}

interface FetchOAuthCode extends FetchBase {
  readonly request: 'fetch-oauth-code'
  readonly data: FetchGraphQLOAuthCode
}

interface FetchOAuthToken extends FetchBase {
  readonly request: 'fetch-oauth-token'
  readonly data: FetchGraphQLOAuthToken
}

interface FetchValidateIdentityToken extends FetchBase {
  readonly request: 'validate-identity-token'
}

type AllRequests =
  | FetchUser
  | FetchEntitlements
  | FetchOAuthCode
  | FetchOAuthToken
  | FetchValidateIdentityToken

interface FetchPendingAction extends FetchBase {
  readonly time: number
}

interface FetchFailedAction extends FetchBase {
  readonly message: string
}

const requestSlice = createSlice({
  name: 'request',
  initialState: DEFAULT_REQUEST_STATE,
  reducers: {
    /**
     * Sets the given request has `pending`.
     */
    fetchPending: (state, action: PayloadAction<FetchPendingAction>) => {
      state[action.payload.request] = {
        ...state[action.payload.request],
        pending: true,
        success: false,
        failed: false,
        failedMessage: '',
        time: action.payload.time
      }
    },

    /**
     * Sets the given request has `success`.
     */
    fetchSuccess: (state, action: PayloadAction<AllRequests>) => {
      state[action.payload.request] = {
        ...state[action.payload.request],
        pending: false,
        success: true,
        failed: false,
        failedMessage: ''
      }
    },

    /**
     * Sets the given request has `failed`.
     */
    fetchFailed: (state, action: PayloadAction<FetchFailedAction>) => {
      state[action.payload.request] = {
        ...state[action.payload.request],
        pending: false,
        success: false,
        failed: true,
        failedMessage: action.payload.message
      }
    }
  },
  extraReducers: (builder) => {
    /**
     * Clears request state.
     */
    builder.addCase(clearStore, () => DEFAULT_REQUEST_STATE)
  }
})

export const { actions, reducer } = requestSlice
