import { configureStore } from '@reduxjs/toolkit'

import { reducer as config } from './slices/config'
import { reducer as account } from './slices/account'
import { reducer as entitlements } from './slices/entitlements'
import { reducer as authentication } from './slices/authentication'
import { reducer as onboarding } from './slices/onboarding'
import { reducer as launchDarkly } from './slices/launch-darkly'
import { reducer as request } from './slices/request'

import { ENVIRONMENT } from '../constants'

import type { RootState } from './types'

/**
 * Create a Redux store.
 *
 * @param initialState Initial state to use for the Redux store.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createStore = (preloadedState: Partial<RootState>) => (
  configureStore({
    preloadedState,
    reducer: {
      config,
      account,
      entitlements,
      authentication,
      onboarding,
      launchDarkly,
      request
    },
    devTools: ENVIRONMENT === 'development'
  })
)

export default createStore
