// ***** Constants *****

export const DEFAULT_ERROR_MESSAGE = 'Something unexpected happened.'

/**
 * Gets an error message for a given input.
 *
 * @param e Error of unknown type.
 * @returns Returns a string representing the description of the error.
 */
const getError = (e: unknown): string => (
  e instanceof Error
    ? e.message
    : DEFAULT_ERROR_MESSAGE
)

export default getError
