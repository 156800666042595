import { name, version } from '../package.json'
import type { CopilotPlugins, Environment, Platform } from './types'
import type { FunctionalitiesConfiguration } from './universal-sidebar-communicator'

/**
 * List of supported environment.
 */
export const ENVIRONMENTS = ['development', 'staging', 'production'] as const

/**
 * List of supported platform.
 */
export const PLATFORMS = ['microsoft-office-add-in', 'google-add-on'] as const

/**
 * Current environment of the application.
 */
export const ENVIRONMENT =
  (process.env.ENVIRONMENT as Environment) || 'development'

/**
 * Current version of the application.
 */
export const VERSION = version

/**
 * Current name of the application.
 */
export const APP_NAME = name

/**
 * Datadog client token.
 */
export const DATADOG_CLIENT_TOKEN = process.env.DATADOG_CLIENT_TOKEN ?? ''

/**
 * Datadog site string.
 */
export const DATADOG_SITE = process.env.DATADOG_SITE ?? ''

/**
 * Launch-Darkly client-id.
 */
export const LAUNCH_DARKLY_CLIENT_ID =
  process.env.LAUNCH_DARKLY_CLIENT_ID ?? ''

/**
 * Object of GraphQL URL per environment.
 */
export const GRAPHQL_URL_BY_ENVIRONMENT: Record<Environment, string> = {
  development: 'http://localhost:4040/graphql',
  staging: 'https://i-staging.jasper.ai/gw/graphql',
  production: 'https://i.jasper.ai/gw/graphql'
}

/**
 * GraphQL URL for the current environment.
 */
export const GRAPHQL_URL = GRAPHQL_URL_BY_ENVIRONMENT[ENVIRONMENT]

/**
 * Object of Functions URL per environment.
 */
export const BACKEND_FUNCTIONS_URL_BY_ENVIRONMENT: Record<Environment, string> =
  {
    development: 'http://localhost:3003/api',
    staging: 'https://i-staging.jasper.ai/functions/api',
    production: 'https://i.jasper.ai/functions/api'
  }

/**
 * Function URL for the current environment.
 */
export const BACKEND_FUNCTIONS_URL =
  BACKEND_FUNCTIONS_URL_BY_ENVIRONMENT[ENVIRONMENT]

/**
 * Object of Web-app URL per environment.
 */
export const FRONTEND_WEB_APP_URL_BY_ENVIRONMENT: Record<Environment, string> =
  {
    development: 'http://localhost:3001',
    staging: 'https://app-staging.jasper.ai',
    production: 'https://app.jasper.ai'
  }

/**
 * Web-app frontend URL for the current environment.
 */
export const FRONTEND_WEB_APP_URL =
  FRONTEND_WEB_APP_URL_BY_ENVIRONMENT[ENVIRONMENT]

/**
 * Object of Easy-button Iframe URL per environment.
 */
export const EASY_BUTTON_IFRAME_URL_BY_ENVIRONMENT: Record<
Environment,
string
> = {
  development: `${FRONTEND_WEB_APP_URL_BY_ENVIRONMENT.development}/sidebar-runner`,
  staging: `${FRONTEND_WEB_APP_URL_BY_ENVIRONMENT.staging}/sidebar-runner`,
  production: `${FRONTEND_WEB_APP_URL_BY_ENVIRONMENT.production}/sidebar-runner`
}

/**
 * Easy-button Iframe URL for the current environment.
 */
export const EASY_BUTTON_IFRAME_URL =
  EASY_BUTTON_IFRAME_URL_BY_ENVIRONMENT[ENVIRONMENT]

/**
 * Object of platform origins by environment.
 */
export const PLATFORM_ORIGIN_BY_ENVIRONMENT: Record<
Platform,
Record<Environment, string | RegExp>
> = {
  'microsoft-office-add-in': {
    development: 'https://localhost:3048',
    staging: 'https://microsoft-office-add-in.jasperpreview.app',
    production: 'https://microsoft-office-add-in.jasper.ai'
  },
  'google-add-on': {
    development: 'http://localhost:3048',
    staging: /^(https:\/\/)[a-zA-Z0-9-]+(\.googleusercontent\.com)$/,
    production: /^(https:\/\/)[a-zA-Z0-9-]+(\.googleusercontent\.com)$/
  }
}

/**
 * Unique string representing an error being use when the user
 * object retrieved from the backend is an inactive user account.
 */
export const INACTIVE_USER_ERROR = 'INACTIVE_USER_TYPE'

// ***** Firebase Credentials *****

export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY ?? ''
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN ?? ''
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID ?? ''
export const FIREBASE_APP_ID = process.env.FIREBASE_APP_ID ?? ''

// ***** Fingerprint JS *****

export const FINGERPRINT_JS_TOKEN = process.env.FINGERPRINT_JS_TOKEN ?? ''
export const FINGERPRINT_JS_ENDPOINT =
  process.env.FINGERPRINT_JS_ENDPOINT ?? ''
export const FINGERPRINT_JS_SCRIPT_URL_PATTERN =
  process.env.FINGERPRINT_JS_SCRIPT_URL_PATTERN ?? ''

/**
 * Default object of functionalities.
 *
 * /!\ Important notes /!\
 * Default values should always be in a "disabled" state. The consumer should be
 * the only entity able to enable a feature.
 */
export const DEFAULT_FUNCTIONALITIES_CONFIGURATION: FunctionalitiesConfiguration =
  {
    authentication: 'consumer',
    supportedTextInsertion: 'classic'
  }

/**
 * Subset of features available in Jasper (just the ones we need for this project).
 * Must match the feature names configured in Weaver for entitlements.
 * @see https://github.com/gojasper/weaver-api/blob/master/libs/entitlements/src/constants/
 */
export enum ENTITLEMENTS_FEATURES {
  CHAT = 'CHAT',
}

/**
 * The number of milliseconds to wait between each attempt to fetch the OAuth token.
 */
export const OAUTH_FETCH_TOKEN_POLL_MS = 1000

/**
 * The maximum number of attempts to fetch the OAuth token.
 */
export const OAUTH_FETCH_TOKEN_MAX_ATTEMPTS = 300

/**
 * A list of supported Copilot plugins.
 */
export const SUPPORTED_COPILOT_PLUGINS: CopilotPlugins = {
  messages: [],
  CRPCs: ['get-content-text@v1', 'insert-content-text@v1']
}
