import { createSlice } from '@reduxjs/toolkit'

import { isFetchEntitlements, actions as requestActions } from './request'
import { clearStore } from '../actions'
import { DEFAULT_ENTITLEMENTS_STATE } from '../constants'

const entitlementsSlice = createSlice({
  name: 'entitlements',
  initialState: DEFAULT_ENTITLEMENTS_STATE,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Sets entitlements list in state from entitlements request.
     */
    builder.addCase(requestActions.fetchSuccess, (state, action) => {
      if (!isFetchEntitlements(action.payload)) return state

      return {
        ...state,
        list: action.payload.data
      }
    })

    /**
     * Clears entitlements list.
     */
    builder.addCase(clearStore, () => DEFAULT_ENTITLEMENTS_STATE)
  }
})

export const { actions, reducer } = entitlementsSlice
