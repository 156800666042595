import { createSelector } from '@reduxjs/toolkit'
import {
  getSubscriptionInfo as getSubscriptionInfoPackage,
  getFlagsFromEntitlementList as getFlagsFromEntitlementListPackage
} from '@gojasper/account-mgt'

import { isNonEmptyString } from '../type-guards'
import { ENTITLEMENTS_FEATURES, FRONTEND_WEB_APP_URL, INACTIVE_USER_ERROR } from '../constants'

import type { Entitlement, LaunchDarklyFlags, Platform, User } from '../types'
import type { Requestable, RootState } from './types'
import type { SubscriptionType } from '@gojasper/account-mgt/entitlements/types'
import type { FunctionalitiesConfiguration } from '../universal-sidebar-communicator'

// ***** Config selectors *****

export const getPlatform = (state: RootState): Platform => state.config.platform
export const getVersion = (state: RootState): string => state.config.version
export const getConfiguration = (state: RootState): FunctionalitiesConfiguration => (
  state.config.functionalities
)

// ***** Account selectors *****

export const getUser = (state: RootState): User => state.account.user

// ***** Entitlements selectors *****

export const getEntitlements = (state: RootState): Entitlement[] => state.entitlements.list

// ***** Authentication selectors *****

export const getAuthenticationIdToken = (state: RootState): string => (
  state.authentication.idToken
)
export const getAuthenticationInitialized = (state: RootState): boolean => (
  state.authentication.initialized
)
export const getOAuthCode = (state: RootState): string => (
  state.authentication.oauthCode
)
export const getOAuthExpiry = (state: RootState): number => (
  state.authentication.oauthExpiry
)
export const getOAuthLink = createSelector(
  [getOAuthCode, getPlatform, getVersion], (oauthCode, platform, version) => (
    `${FRONTEND_WEB_APP_URL}/activate?platform=${platform}&version=${version}&code=${oauthCode}`
  )
)

// ***** Onboarding selectors *****

export const getOnboardingShow = (state: RootState): boolean => state.onboarding.show

// ***** Launch-Darkly selectors *****

export const getLaunchDarklyInitialized = (state: RootState): boolean => (
  state.launchDarkly.initialized
)
export const getLaunchDarklyFlags = (state: RootState): LaunchDarklyFlags => (
  state.launchDarkly.flags
)

// ***** Request selectors *****

export const getFetchUser = (state: RootState): Requestable => state.request['fetch-user']
export const getFetchEntitlements = (state: RootState): Requestable => state.request['fetch-entitlements']
export const getFetchOAuthCode = (state: RootState): Requestable => state.request['fetch-oauth-code']
export const getFetchOAuthToken = (state: RootState): Requestable => state.request['fetch-oauth-token']

// ***** Reselect *****

/**
 * Tells if the application has been initialized. This includes:
 *  - Launch-Darkly initialization
 *  - IncomingMessage.Initialize from consumer
 */
export const getIsApplicationInitialized = createSelector(
  getAuthenticationInitialized, getLaunchDarklyInitialized,
  (authenticationInitialized, launchDarklyInitialized) => (
    authenticationInitialized && launchDarklyInitialized
  )
)

/**
 * Tells if the user has been initialized and loaded.
 */
export const getIsUserInitialized = createSelector(
  getAuthenticationIdToken, getUser,
  (token, user) => isNonEmptyString(token) && isNonEmptyString(user.id)
)

/**
 * Tells if the current user account is inactive.
 */
export const getIsInactiveAccount = createSelector(getFetchUser, (request) => (
  request.failed && request.failedMessage === INACTIVE_USER_ERROR
))

/**
 * Gets information about the user subscription.
 */
export const getSubscriptionInfo = createSelector(getUser, (user) => (
  getSubscriptionInfoPackage(user.workspace.subscription as SubscriptionType)
))

/**
 * Gets entitlement flags for the current user.
 */
export const getEntitlementFlags = createSelector(getEntitlements, (entitlements) => (
  getFlagsFromEntitlementListPackage(entitlements, ENTITLEMENTS_FEATURES)
))
