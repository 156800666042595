import { clearStore } from '../actions'
import { DEFAULT_LAUNCH_DARKLY_STATE } from '../constants'

import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RawLaunchDarklyFlags } from '../../types'
import { camelCase } from 'lodash-es'

const launchDarklySlice = createSlice({
  name: 'launchDarkly',
  initialState: DEFAULT_LAUNCH_DARKLY_STATE,
  reducers: {
    /**
     * Sets the initialized status of Launch-Darkly.
     */
    setLaunchDarklyStatus: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload
    },

    /**
     * Sets the Launch-Darkly flags.
     */
    setLaunchDarklyFlags: (
      state,
      action: PayloadAction<RawLaunchDarklyFlags>
    ) => {
      // Transforms raw flags into camel-case flags (for consistency with other
      // platforms).
      state.flags = Object.keys(action.payload).reduce(
        (acc, flag) => ({
          ...acc,
          [camelCase(flag)]: action.payload[flag]
        }),
        state.flags
      )

      state.initialized = true
    }
  },
  extraReducers: (builder) => {
    /**
     * Clears Launch-Darkly state.
     */
    builder.addCase(clearStore, () => DEFAULT_LAUNCH_DARKLY_STATE)
  }
})

export const { actions, reducer } = launchDarklySlice
