import React from 'react'

import SignIn from './sign-in'
import { OAuth } from './oauth'

import { useAppSelector } from '../../hooks/redux'
import { getConfiguration, getOAuthCode } from '../../store/selectors'

import { TEST_IDS } from '../../test/test-ids'
import { isNonEmptyString } from '../../type-guards'
import ErrorModal from '../../components/error-modal'

/**
 * Main authentication router.
 */
const Authentication: React.FC = () => {
  const code = useAppSelector(getOAuthCode)
  const configuration = useAppSelector(getConfiguration)

  // Consumer should have handled authentication...
  if (configuration.authentication === 'consumer') {
    return (
      <ErrorModal
        title='Oops!'
        message='We could not identify you. Reload the page to try again.'
        data-testid={TEST_IDS.CONSUMER_AUTH_ERROR_MODAL}
      />
    )
  }

  // OAuth flow is processing...
  if (isNonEmptyString(code)) return <OAuth />

  // ...otherwise, render the initial sign-in form.
  return <SignIn />
}

export default Authentication
