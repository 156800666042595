import React from 'react'

import {
  getAuthenticationIdToken,
  getIsApplicationInitialized,
  getOnboardingShow,
  getIsInactiveAccount,
  getIsUserInitialized
} from '../store/selectors'
import { useAppSelector } from '../hooks/redux'

import Loading from '../components/loading'
import Authentication from './authentication'
import Onboarding from './onboarding'
import InactiveAccount from '../components/inactive-account'
import Header from '../components/header'
import EasyButton from './easy-button'

import { isNonEmptyString } from '../type-guards'

const Main: React.FC = () => {
  const isApplicationInitialized = useAppSelector(getIsApplicationInitialized)
  const shouldShowOnboarding = useAppSelector(getOnboardingShow)
  const token = useAppSelector(getAuthenticationIdToken)
  const isUserInitialized = useAppSelector(getIsUserInitialized)
  const isInactiveAccount = useAppSelector(getIsInactiveAccount)

  // Application hasn't been initialized yet...
  if (!isApplicationInitialized) return <Loading />

  // User never been through the onboarding...
  if (shouldShowOnboarding) return <Onboarding />

  // User isn't authenticated...
  if (!isNonEmptyString(token)) return <Authentication />

  // User account isn't active...
  if (isInactiveAccount) return <InactiveAccount />

  // User isn't initialized yet...
  if (!isUserInitialized) return <Loading />

  // App has been initialized, components can now render...
  return (
    <div className='flex flex-col w-screen h-screen bg-gradient-to-b from-[#eae9f8] via-khaki-50 via-10% to-khaki-50'>
      <Header />
      <EasyButton />
    </div>
  )
}

export default Main
