import {
  type LDClient,
  type LDContext,
  type LDFlagChangeset,
  initialize as launchDarklyInitialize
} from 'launchdarkly-js-client-sdk'

import type { AppStore } from '../store/types'
import { actions as launchDarklyActions } from '../store/slices/launch-darkly'
import { getPlatform, getSubscriptionInfo, getUser, getVersion } from '../store/selectors'

import Datadog from './datadog'
import getError from './get-error'
import { isNonEmptyString } from '../type-guards'
import { LAUNCH_DARKLY_CLIENT_ID } from '../constants'

// ***** Constants *****

const ANONYMOUS_KEY = 'jasper-anonymous'

const DEFAULT_LAUNCH_DARKLY_USER: LDContext = {
  kind: 'user',
  key: ANONYMOUS_KEY,
  anonymous: true
}

// ***** Helper functions *****

const isEnabled = (): boolean => (
  // A client-id has been provided.
  isNonEmptyString(LAUNCH_DARKLY_CLIENT_ID)
)

class LaunchDarkly {
  private static instance: LaunchDarkly

  private readonly store: AppStore

  private client: LDClient | null

  constructor (store: AppStore) {
    this.store = store
    this.client = null

    // Initialize the SDK...
    void this.initializeSDK()
  }

  public static init (store: AppStore): LaunchDarkly {
    if (LaunchDarkly.instance) return LaunchDarkly.instance

    LaunchDarkly.instance = new LaunchDarkly(store)
    return LaunchDarkly.instance
  }

  public static getInstance (): LaunchDarkly {
    if (!LaunchDarkly.instance) throw new Error('init() must be call first.')
    return LaunchDarkly.instance
  }

  /**
   * Initialize the Launch-Darkly SDK with an anonymous user.
   */
  private async initializeSDK (): Promise<void> {
    if (!isEnabled()) return

    const state = this.store.getState()
    const platform = getPlatform(state)
    const version = getVersion(state)

    // Initialize Launch-Darkly with a default user...
    this.client = launchDarklyInitialize(LAUNCH_DARKLY_CLIENT_ID, DEFAULT_LAUNCH_DARKLY_USER, {
      application: {
        id: platform,
        version
      }
    })

    try {
      // Wait for Launch-Darkly to finish initializing...
      await this.client.waitForInitialization()

      // Save the Launch-Darkly flags in the store...
      const flags = this.client.allFlags()
      this.store.dispatch(launchDarklyActions.setLaunchDarklyFlags(flags))

      // Listen on flag changes, and update the store appropriately...
      this.client.on('change', (changes: LDFlagChangeset): void => {
        const flags = Object.entries(changes).reduce<Record<string, unknown>>(
          (acc, [key, { current }]) => {
            acc[key] = current
            return acc
          },
          {}
        )

        this.store.dispatch(launchDarklyActions.setLaunchDarklyFlags(flags))
      })
    } catch (e) {
      Datadog.error({
        message: getError(e),
        error: e instanceof Error ? e : undefined
      })
    }
  }

  /**
   * Internal function to change the Launch-Darkly context and save the
   * updated flags in the store.
   */
  private async _identify (context: LDContext): Promise<void> {
    if (!isEnabled() || !this.client) return

    try {
      // Set the Launch-Darkly status, side-effect of showing a global loading state...
      this.store.dispatch(launchDarklyActions.setLaunchDarklyStatus(false))

      // Identify the current user with Launch-Darkly...
      const flags = await this.client.identify(context)

      // Launch-Darkly has now identified the user...
      this.store.dispatch(launchDarklyActions.setLaunchDarklyFlags(flags))
    } catch (e) {
      Datadog.error({
        message: getError(e),
        error: e instanceof Error ? e : undefined
      })
    }
  }

  /**
   * Identify the current user in the Launch-Darkly context.
   */
  public static async identify (): Promise<void> {
    const instance = this.getInstance()
    const state = instance.store.getState()
    const user = getUser(state)
    const { productLabel } = getSubscriptionInfo(state)

    await instance._identify({
      kind: 'user',
      key: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      name: `${user.firstName} ${user.lastName}`,
      workspaceId: user.workspace.id,
      promoId: user.workspace.promoId,
      product: productLabel,
      createdAt: user.created_at,
      workspaceUserCount: user.workspace.workspaceUserCount.aggregate.count,
      workspaceCreatedAt: user.workspace.created_at,
      workspaceActivatedAt: user.workspace.firstActivated,
      anonymous: false
    })
  }

  /**
   * Remove the current user from the Launch-Darkly SDK and set up a default
   * anonymous user instead.
   */
  public static async clear (): Promise<void> {
    const instance = this.getInstance()
    await instance._identify(DEFAULT_LAUNCH_DARKLY_USER)
  }
}

export default LaunchDarkly
