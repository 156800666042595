import { version } from '../../package.json'
import { getDeviceId } from './fingerprint'

interface SecurityHeaders {
  /**
     * Represent the device ID.
     */
  readonly dt: string

  /**
     * Represent the device ID hash.
     */
  readonly dth: string

  /**
     * Version of the application.
     */
  readonly version: string

  /**
     * Hostname of the application.
     */
  readonly hn: string

  /**
     * Unknown. TODO: find out what is this property for.
     */
  readonly b: string
}

const getB = (dt: string | null, dth: string | null, version: string, hn: string): string => {
  try {
    return btoa(JSON.stringify({ dt, dth, version, hn }))
  } catch (e) {
    return ''
  }
}

const getSecurityHeaders = async (): Promise<SecurityHeaders> => {
  const { dt, dth } = typeof document !== 'undefined' ? await getDeviceId() : { dt: '', dth: '' }
  const hostname = window.location.hostname

  return {
    dt,
    dth,
    hn: btoa(hostname),
    version,
    b: getB(dt, dth, version, hostname)
  }
}

export default getSecurityHeaders
