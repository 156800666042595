import React from 'react'

import ErrorModal from './error-modal'
import { FRONTEND_WEB_APP_URL } from '../constants'
import { TEST_IDS } from '../test/test-ids'

const InactiveAccount: React.FC = () => (
  <ErrorModal
    title='Oops!'
    message='We&apos;re sorry, but your account hasn&apos;t been set up yet. Please click on the button below to complete the account setup process.'
    button='My account'
    onButtonClick={(): void => {
      window.open(FRONTEND_WEB_APP_URL, '__blank')
    }}
    data-testid={TEST_IDS.INACTIVE_ACCOUNT_MODAL}
  />
)

export default InactiveAccount
