{
  "name": "@gojasper/universal-sidebar",
  "version": "0.2.29",
  "files": [
    "dist/**/*"
  ],
  "source": "public/index.html",
  "scripts": {
    "standalone": "concurrently \"npm start\" \"(cd standalone; npm start)\"",
    "start": "parcel --port 3023 --no-cache",
    "build": "parcel build",
    "type-check": "tsc --noEmit",
    "lint": "eslint . --fix",
    "test": "jest",
    "prepare": "husky install",
    "setup": "npm run link && npm run env",
    "link": "npx vercel link --scope jasperai",
    "env": "npx vercel env pull",
    "postinstall": "(cd standalone; npm install)"
  },
  "peerDependencies": {
    "react": "^18.2.0",
    "react-dom": "^18.2.0"
  },
  "devDependencies": {
    "@commitlint/cli": "^17.6.1",
    "@commitlint/config-conventional": "^17.6.1",
    "@parcel/packager-ts": "^2.8.3",
    "@parcel/transformer-typescript-types": "^2.8.3",
    "@redux-devtools/core": "^3.13.1",
    "@testing-library/jest-dom": "^5.16.5",
    "@testing-library/react": "^14.0.0",
    "@types/jest": "^29.5.1",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^18.15.11",
    "@types/react": "^18.0.32",
    "@types/react-dom": "^18.0.11",
    "@typescript-eslint/eslint-plugin": "^5.57.0",
    "@typescript-eslint/parser": "^5.57.0",
    "autoprefixer": "^10.4.14",
    "concurrently": "^8.1.0",
    "conventional-changelog-preset-loader": "^2.3.4",
    "core-js": "^3.28.0",
    "eslint": "^8.37.0",
    "eslint-config-standard-react": "^13.0.0",
    "eslint-config-standard-with-typescript": "^34.0.1",
    "eslint-plugin-communist-spelling": "^1.0.0",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-node": "^11.1.0",
    "eslint-plugin-promise": "^6.1.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "eslint-plugin-standard": "^5.0.0",
    "eslint-plugin-unicorn": "^47.0.0",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.5.0",
    "parcel": "^2.8.3",
    "postcss": "^8.4.21",
    "process": "^0.11.10",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "tailwindcss": "^3.3.1",
    "ts-jest": "^29.1.0",
    "typescript": "^5.0.3",
    "util": "^0.12.5",
    "vercel": "^29.0.3"
  },
  "dependencies": {
    "@datadog/browser-logs": "^4.41.0",
    "@fingerprintjs/fingerprintjs-pro-spa": "^1.0.2",
    "@gojasper/account-mgt": "^0.1.292",
    "@gojasper/atlas-tracking-sdk": "^0.2.722",
    "@gojasper/base-ui": "^0.2.722",
    "@gojasper/brand-standards": "^0.2.722",
    "@reduxjs/toolkit": "^1.9.3",
    "firebase": "^9.21.0",
    "framer-motion": "^10.12.4",
    "graphql-request": "^6.1.0",
    "husky": "^8.0.3",
    "launchdarkly-js-client-sdk": "^3.1.3",
    "lodash-es": "^4.17.21",
    "react-redux": "^8.0.5",
    "redux": "^4.2.1",
    "tailwind-merge": "^1.11.0",
    "uuidv4": "^6.2.13"
  }
}
