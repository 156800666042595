import { BACKEND_FUNCTIONS_URL } from '../constants'
import { getAuthenticationIdToken } from '../store/selectors'

import type { AppThunkAction } from '../store/types'

// ***** Types *****
interface RequestOptions extends RequestInit {
  /**
   * Tells if the request requires the `authorization` header.
   */
  readonly public?: boolean

  /**
   * Tells if the request is expecting a JSON response.
   */
  readonly expectJsonResponse?: boolean
}

/**
 * Helper function that wrap business logic around the basic `fetch` method.
 *
 * Note this method doesn't validate the returned value. Which means the return
 * type will always be `unknown`. Consumer is responsible for validating the value.
 *
 * @param endpoint Pathname of the endpoint to hit.
 * @param options Object of request options.
 * @returns Returns the parsed version of the returned value. Or throw if the
 * request failed.
 */
const request = (
  endpoint: string,
  options?: RequestOptions
): AppThunkAction<Promise<unknown>> =>
  async (_, getState): Promise<unknown> => {
    const state = getState()
    const token = getAuthenticationIdToken(state)
    const isPublicReq = options?.public ?? false
    const expectJsonResponse = options?.expectJsonResponse ?? true

    if (!isPublicReq && !token) throw new Error('Authentication required.')

    const response = await fetch(`${BACKEND_FUNCTIONS_URL}/${endpoint}`, {
      ...options,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options?.headers,
        ...token ? { authorization: `Bearer ${token}` } : {}
      }
    })

    if (!response.ok) {
      // Parse the response to get the error message...
      let payload
      try {
        payload = await response.json()
      } catch (_) {
        // Ignore parsing error...
      }

      if (payload && typeof payload === 'object' && 'message' in payload) {
        throw new Error(payload.message)
      } else {
        throw new Error(`Invalid request for: "${endpoint}". Response code: "${response.status}".`)
      }
    }
    if (!expectJsonResponse) return undefined

    let content: unknown | undefined

    try {
      content = await response.json()
    } catch (e) {
      throw new Error('Could not parse response.')
    }

    return content
  }

export default request
