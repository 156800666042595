import { initializeApp } from 'firebase/app'
import {
  type Auth,
  type User,
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut
} from 'firebase/auth'

import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID
} from '../constants'

import { type AppStore } from '../store/types'
import { actions as authenticationActions } from '../store/slices/authentication'
import { initialize } from '../store/thunks'

class Firebase {
  private static instance: Firebase

  private readonly store: AppStore

  private readonly authentication: Auth

  constructor (store: AppStore) {
    const app = initializeApp({
      apiKey: FIREBASE_API_KEY,
      authDomain: FIREBASE_AUTH_DOMAIN,
      databaseURL: `https://${FIREBASE_PROJECT_ID}.firebaseio.com`,
      projectId: FIREBASE_PROJECT_ID,
      appId: FIREBASE_APP_ID
    })

    this.store = store
    this.authentication = getAuth(app)
  }

  public static init (store: AppStore): Firebase {
    if (Firebase.instance) return Firebase.instance

    Firebase.instance = new Firebase(store)
    return Firebase.instance
  }

  public static getInstance (): Firebase {
    if (!Firebase.instance) throw new Error('init() must be called first.')
    return Firebase.instance
  }

  /**
   * Given a Firebase custom-token, authenticate the user with Firebase and
   * fetch all necessary state.
   *
   * @param token Firebase custom token.
   */
  public async authenticate (token: string): Promise<void> {
    // Exchange the Jasper token for a Firebase credentials...
    const { user } = await signInWithCustomToken(this.authentication, token)

    // Set up the user in the store...
    await this.initialize(user)
  }

  /**
   * Tries to restore the previous authenticated user.
   */
  public async restore (): Promise<void> {
    const user = await new Promise<User | null>((resolve) => {
      onAuthStateChanged(this.authentication, resolve)
    })

    // A user is already logged in...
    if (user) await this.initialize(user)
  }

  /**
   * Signs out the user.
   */
  public async logout (): Promise<void> {
    await signOut(this.authentication)
  }

  /**
   * Fetches an id-token for the given user, automatically saves
   * it in the global store and load all required states.
   *
   * @param user A Firebase user object.
   */
  private async initialize (user: User): Promise<void> {
    // Retrieves the user token.
    const token = await user.getIdToken()

    // Saves the token in the store.
    this.store.dispatch(authenticationActions.setIdToken(token))

    // Load the mandatory states.
    await this.store.dispatch(initialize())
  }
}

export default Firebase
