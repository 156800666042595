import { createSlice } from '@reduxjs/toolkit'

import { isFetchUser, actions as requestActions } from './request'
import { clearStore } from '../actions'
import { DEFAULT_ACCOUNT_STATE } from '../constants'

const accountSlice = createSlice({
  name: 'account',
  initialState: DEFAULT_ACCOUNT_STATE,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * Sets user in state from user request.
     */
    builder.addCase(requestActions.fetchSuccess, (state, action) => {
      if (!isFetchUser(action.payload)) return state

      return {
        ...state,
        user: action.payload.data
      }
    })

    /**
     * Clears account state.
     */
    builder.addCase(clearStore, () => DEFAULT_ACCOUNT_STATE)
  }
})

export const { actions, reducer } = accountSlice
