import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { isFetchOAuthCode, actions as requestActions } from './request'
import { clearStore } from '../actions'
import { DEFAULT_AUTHENTICATION_STATE } from '../constants'

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: DEFAULT_AUTHENTICATION_STATE,
  reducers: {
    /**
     * Sets the `initialized` state.
     */
    setInitialized: (state, action: PayloadAction<boolean>) => {
      state.initialized = action.payload
    },

    /**
     * Sets a Firebase authentication token in the store.
     */
    setIdToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(requestActions.fetchSuccess, (state, action) => {
      // Set the OAuth Code and Expiry...
      if (isFetchOAuthCode(action.payload)) {
        return {
          ...state,
          oauthCode: action.payload.data.oauthCode.code,
          oauthExpiry: action.payload.data.oauthCode.expiry
        }
      }

      return state
    })

    builder.addCase(requestActions.fetchFailed, (state, action) => {
      // Reset the OAuth Flow if polling for the token fails...
      if (action.payload.request === 'fetch-oauth-token') {
        return {
          ...state,
          oauthCode: '',
          oauthExpiry: 0
        }
      }

      return state
    })

    // Clears authentication state...
    builder.addCase(clearStore, (state) => ({
      ...DEFAULT_AUTHENTICATION_STATE,
      initialized: state.initialized
    }))
  }
})

export const { actions, reducer } = authenticationSlice
