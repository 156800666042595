import { isNonEmptyString } from './type-guards'

/**
 * Tells if a given input is an identified iframe Message for the Universal-sidebar.
 */
export const isMessageIdentifier = (
  tbd: any
): tbd is MessageIdentifier<unknown> =>
  typeof tbd === 'object' && isNonEmptyString(tbd.id)

/**
 * Tells if a given input is an incoming message.
 */
export const isIncomingMessage = (tbd: any): tbd is IncomingMessage =>
  typeof tbd === 'object' &&
  isNonEmptyString(tbd.type) &&
  Object.values(IncomingMessageType).includes(tbd.type)

/**
 * Tells if a given input is an outgoing message.
 */
export const isOutgoingMessage = (tbd: any): tbd is OutgoingMessage =>
  typeof tbd === 'object' &&
  isNonEmptyString(tbd.type) &&
  Object.values(OutgoingMessageType).includes(tbd.type)

/**
 * Object of functionalities the Universal-sidebar supports.
 */
export interface FunctionalitiesConfiguration {
  /**
   * Type of authentication flow to enable:
   *  - `built-in` - All authentication happens in the Sidebar, sign-in forms
   * get rendered when needed.
   *  - `consumer` - Sidebar consumer handle passing an Identity-token in order
   * for the sidebar to initialize Firebase and sign-in the user.
   */
  readonly authentication: 'built-in' | 'consumer'

  /**
   * Type of supported text insertion:
   *  - `classic` - Platform doesn't handle `markdown` string.
   *  - `markdown` - Platform does handle `markdown` string insertion.
   */
  readonly supportedTextInsertion: 'classic' | 'markdown'
}

/**
 * Iframe message with an identifier.
 */
export interface MessageIdentifier<T> {
  readonly id: string
  readonly message: T
}

/**
 * Base type of a message.
 */
export interface MessageBase<T> {
  readonly type: T
}

// ***** Incoming messages *****

/**
 * Type of messages that can be send from the consumer to the sidebar.
 */
export enum IncomingMessageType {
  Initialize = 'INITIALIZE',
  Promise = 'PROMISE',
}

/**
 * Initialize message is the first message that must be send to the sidebar.
 */
export interface IncomingInitializeMessage
  extends MessageBase<IncomingMessageType> {
  readonly type: IncomingMessageType.Initialize
  readonly payload: {
    /**
     * Object of functionalities - it allows the consumer to customize
     * the sidebar experience.
     */
    readonly configuration: Partial<FunctionalitiesConfiguration>

    /**
     * Optional user identity-token. This is being used by the sidebar
     * to authenticate the user when `configuration.authentication = 'consumer'`.
     */
    readonly identityToken?: string
  }
}

/**
 * Promise message is used to resolve or reject a promise that has been created
 * by the beta-app.
 */
export interface IncomingPromiseMessage
  extends MessageBase<IncomingMessageType> {
  readonly type: IncomingMessageType.Promise
  readonly payload: {
    readonly state: 'fulfilled' | 'rejected'
    readonly value?: unknown
  }
}

/**
 * Union of all possible incoming messages.
 */
export type IncomingMessage =
  | IncomingInitializeMessage
  | IncomingPromiseMessage

// ***** Outgoing messages *****

/**
 * Type of messages that can be sent from the sidebar to the consumer.
 */
export enum OutgoingMessageType {
  Initialize = 'INITIALIZE',
  /**
   * @deprecated This is used by the `chat-runner`, but it should be removed
   * once the `chat-runner` is entirely removed.
   */
  SetText = 'SET_TEXT',
  StreamStart = 'STREAM_START',
  StreamEnd = 'STREAM_END',
  StreamInsert = 'STREAM_INSERT',
  InsertContent = 'INSERT_CONTENT',
  GetContent = 'GET_CONTENT',
  GetCopilotSupportedPluginIdentifiers = 'GET_COPILOT_SUPPORTED_PLUGIN_IDENTIFIERS',
}

export interface OutgoingInitializeMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.Initialize
  readonly payload: {
    readonly identityToken: string
  }
}

/**
 * Insert the given text at the given position in the document. The text
 * will be inserted at the current cursor position (or replace the
 * current selection if selection is expanded).
 *
 * @deprecated This is used by the `chat-runner`, but it should be removed
 * once the `chat-runner` is entirely removed.
 */
export interface OutgoingSetTextMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.SetText
  readonly payload: {
    readonly text: string
  }
}

export interface OutgoingStreamStartMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.StreamStart
  /**
   * Setting this to `unknown` as the Universal-Sidebar doesn't care about
   * the payload of this message.
   */
  readonly payload: unknown
}

export interface OutgoingStreamEndMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.StreamEnd
  /**
   * Setting this to `unknown` as the Universal-Sidebar doesn't care about
   * the payload of this message.
   */
  readonly payload: unknown
}

export interface OutgoingStreamInsertMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.StreamInsert
  /**
   * Setting this to `unknown` as the Universal-Sidebar doesn't care about
   * the payload of this message.
   */
  readonly payload: unknown
}

export interface OutgoingInsertContentMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.InsertContent
  /**
   * Setting this to `unknown` as the Universal-Sidebar doesn't care about
   * the payload of this message.
   */
  readonly payload: unknown
}

export interface OutgoingGetContentMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.GetContent
  /**
   * Setting this to `unknown` as the Universal-Sidebar doesn't care about
   * the payload of this message.
   */
  readonly payload: unknown
}

export interface OutgoingGetCopilotSupportedPluginIdentifiersMessage
  extends MessageBase<OutgoingMessageType> {
  readonly type: OutgoingMessageType.GetCopilotSupportedPluginIdentifiers
}

/**
 * Union of all possible outgoing messages.
 */
export type OutgoingMessage =
  | OutgoingInitializeMessage
  | OutgoingSetTextMessage
  | OutgoingStreamStartMessage
  | OutgoingStreamEndMessage
  | OutgoingStreamInsertMessage
  | OutgoingInsertContentMessage
  | OutgoingGetContentMessage
  | OutgoingGetCopilotSupportedPluginIdentifiersMessage
