import React from 'react'
import { twMerge } from 'tailwind-merge'

import { TEST_IDS } from '../test/test-ids'
import { Button } from '@gojasper/base-ui/ava'
import { Icon, IconSizes, IconStyles } from '@gojasper/brand-standards/icons'
import SlashCircle from '@gojasper/brand-standards/icons/SlashCircle'

// ***** Types *****

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  readonly title: string
  readonly message: string
  readonly button?: string
  readonly onButtonClick?: () => void
}

const ErrorModal: React.FC<Props> = ({
  title,
  message,
  className,
  button,
  onButtonClick,
  ...props
}) => (
  <div
    className={twMerge('w-screen h-screen flex items-center justify-center', className)}
    {...props}
  >
    <div
      className='relative min-w-[270px] max-w-[270px] min-h-[320px] bg-white rounded-[10px] shadow-lg p-[30px] flex flex-col justify-around'
    >
      <div className='flex flex-col gap-8 items-center justify-center'>
        <div className='flex flex-col justify-center gap-4'>
          <div className='flex items-center justify-center'>
            <Icon Component={SlashCircle} size={IconSizes.XL} style={IconStyles.Line} />
          </div>
          <h1 className='text-3xl font-semibold text-center'>{title}</h1>
          <span className='text-xs font-normal text-center'>
            {message}
          </span>
        </div>
        {!!button && !!onButtonClick && (
          <Button text={button} variant='primary' onClick={onButtonClick} testIdOverride={TEST_IDS.ERROR_MODAL_BUTTON} />
        )}
      </div>
    </div>
  </div>
)

export default ErrorModal
