import React from 'react'

import ErrorModal from '../components/error-modal'
import Datadog from '../utils/datadog'

// ***** Types *****

interface Props {
  readonly children: React.ReactNode
}

interface State {
  readonly failed: boolean
}

/**
 * Catch all React error while rendering children component. Render a
 * fallback component and report the error to Datadog.
 */
class ErrorBoundary extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props)

    this.state = {
      failed: false
    }
  }

  public componentDidCatch (error: Error): void {
    // Set the local state, which shows an Error modal...
    this.setState((state) => ({ ...state, failed: true }))

    // Report the error to Datadog...
    Datadog.error({ message: error.message, error })
  }

  public render (): React.ReactNode {
    if (this.state.failed) {
      return (
        <ErrorModal
          title='Oops!'
          message='Something went wrong, our team has been alerted. Reload the page to try again.'
          className='absolute z-50'
        />
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
