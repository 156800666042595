import type { User } from '../types'

/**
 * Gets a formatted full-name for a given user.
 *
 * @param user Partial user object to get the full-name from.
 * @returns Returns a full name string for the given user.
 */
const getFullName = (user: Pick<User, 'firstName' | 'lastName'>): string => (
    `${user.firstName.trim() || ''} ${user.lastName.trim() || ''}`.trim()
)

export default getFullName
