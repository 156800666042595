import { jsx as o, jsxs as n } from "react/jsx-runtime";
import { A as r } from "../consts-3d3b7db3.mjs";
import { I as e } from "../types-ac618773.mjs";
import { C as s } from "../color-a1f65061.mjs";
const a = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.25",
    d: "M9.333 9.917 12.25 7m0 0L9.333 4.083M12.25 7h-7m0-5.25H3.617c-.654 0-.98 0-1.23.127-.22.112-.398.29-.51.51-.127.25-.127.576-.127 1.23v6.766c0 .654 0 .98.127 1.23.112.22.29.398.51.51.25.127.576.127 1.23.127H5.25"
  }
) }), d = a, u = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ n("svg", { xmlns: "http://www.w3.org/2000/svg", width: "14", height: "14", fill: "none", children: [
  /* @__PURE__ */ o("path", { fill: t, d: "M1.75 12.25V1.75h3.5v10.5z", opacity: ".12" }),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.25",
      d: "M9.333 9.917 12.25 7m0 0L9.333 4.083M12.25 7h-7m0-5.25H3.617c-.654 0-.98 0-1.23.127-.22.112-.398.29-.51.51-.127.25-.127.576-.127 1.23v6.766c0 .654 0 .98.127 1.23.112.22.29.398.51.51.25.127.576.127 1.23.127H5.25"
    }
  )
] }), g = u, w = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.5",
    d: "M10.667 11.333 14 8m0 0-3.333-3.333M14 8H6m0-6H4.133c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583C2 3.013 2 3.387 2 4.133v7.734c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145H6"
  }
) }), m = w, p = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ n("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "none", children: [
  /* @__PURE__ */ o("path", { fill: t, d: "M2 14V2h4v12z", opacity: ".12" }),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.5",
      d: "M10.667 11.333 14 8m0 0-3.333-3.333M14 8H6m0-6H4.133c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583C2 3.013 2 3.387 2 4.133v7.734c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145H6"
    }
  )
] }), L = p, v = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "M13.333 14.167 17.5 10m0 0-4.167-4.167M17.5 10h-10m0-7.5H5.167c-.934 0-1.4 0-1.757.182-.314.16-.569.414-.728.728-.182.357-.182.823-.182 1.757v9.666c0 .934 0 1.4.182 1.757.16.314.414.569.728.728.357.182.823.182 1.757.182H7.5"
  }
) }), k = v, C = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ n("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", children: [
  /* @__PURE__ */ o("path", { fill: t, d: "M2.5 17.5v-15h5v15z", opacity: ".12" }),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "M13.333 14.167 17.5 10m0 0-4.167-4.167M17.5 10h-10m0-7.5H5.167c-.934 0-1.4 0-1.757.182-.314.16-.569.414-.728.728-.182.357-.182.823-.182 1.757v9.666c0 .934 0 1.4.182 1.757.16.314.414.569.728.728.357.182.823.182 1.757.182H7.5"
    }
  )
] }), H = C, f = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ o("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: /* @__PURE__ */ o(
  "path",
  {
    stroke: t,
    strokeLinecap: "square",
    strokeWidth: "1.75",
    d: "m16 17 5-5m0 0-5-5m5 5H9m0-9H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 4.52 3 5.08 3 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 21 5.08 21 6.2 21H9"
  }
) }), i = f, M = ({ color: t = s.CurrentColor }) => /* @__PURE__ */ n("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", children: [
  /* @__PURE__ */ o("path", { fill: t, d: "M3 21V3h6v18z", opacity: ".12" }),
  /* @__PURE__ */ o(
    "path",
    {
      stroke: t,
      strokeLinecap: "square",
      strokeWidth: "1.75",
      d: "m16 17 5-5m0 0-5-5m5 5H9m0-9H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C3 4.52 3 5.08 3 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C4.52 21 5.08 21 6.2 21H9"
    }
  )
] }), l = M, x = {
  [r.XS]: {
    [e.Line]: (t) => /* @__PURE__ */ o(d, { color: t }),
    [e.Duo]: (t) => /* @__PURE__ */ o(g, { color: t }),
    [e.Filled]: (t) => null
  },
  [r.S]: {
    [e.Line]: (t) => /* @__PURE__ */ o(m, { color: t }),
    [e.Duo]: (t) => /* @__PURE__ */ o(L, { color: t }),
    [e.Filled]: (t) => null
  },
  [r.M]: {
    [e.Line]: (t) => /* @__PURE__ */ o(k, { color: t }),
    [e.Duo]: (t) => /* @__PURE__ */ o(H, { color: t }),
    [e.Filled]: (t) => null
  },
  [r.L]: {
    [e.Line]: (t) => /* @__PURE__ */ o(i, { color: t }),
    [e.Duo]: (t) => /* @__PURE__ */ o(l, { color: t }),
    [e.Filled]: (t) => null
  },
  [r.XL]: {
    [e.Line]: (t) => /* @__PURE__ */ o(i, { color: t }),
    [e.Duo]: (t) => /* @__PURE__ */ o(l, { color: t }),
    [e.Filled]: (t) => null
  }
}, $ = ({ size: t, style: h, color: c }) => x[t][h](c);
export {
  $ as LogOut,
  $ as default
};
